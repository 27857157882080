import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import { capitalize } from '@/utils';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ProfileForm, User } from '@/pages';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/context';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';

export interface AssignCardConfirmDialogProps {
  open: boolean;

  onConfirm?(assignUserId: number): Promise<void>;
  users?: User[];
  onCancel?(): void;
  fromStage?: StageEnum;
  toStage?: StageEnum;
  salesPipeline?: SalesPipeline;
}

export const AssignCardConfirmDialog = ({
  open,
  users,
  salesPipeline,
  fromStage,
  toStage,
  onConfirm,
  onCancel,
}: AssignCardConfirmDialogProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();
  const { toast } = useToast();

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
  });

  const [selectedOption, setSelectedOption] = useState<{
    loading: boolean;
    userId: number;
    setAsDefault: boolean;
  }>({
    loading: false,
    userId: -1,
    setAsDefault: false,
  });

  const isSelectAE = toStage === 'follow_up';
  const role = isSelectAE ? 'Account Executive' : 'Loan Officer';
  const userPreferenceFieldName = isSelectAE
    ? 'lastAssignedToAeUserId'
    : 'lastAssignedToLoUserId';
  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value;
      setSelectedOption((prev) => ({
        ...prev,
        userId: parseInt(selectedValue),
      }));
    },
    []
  );

  const showUsers = useMemo(
    () =>
      users
        ?.filter(
          (u) =>
            u.isActive &&
            !u.isSuperuser &&
            u.userType === (isSelectAE ? 'AE' : 'LO')
        )
        .sort((a, b) => a.lastName.localeCompare(b.lastName)) ?? [],
    [isSelectAE, users]
  );

  useEffect(() => {
    if (open) {
      const userPreference = userDetails[userPreferenceFieldName] ?? -1;
      const userId = userDetails[userPreferenceFieldName]
        ? showUsers?.map((user) => user.id).includes(userPreference)
          ? userPreference
          : 0
        : -1;

      setSelectedOption({
        loading: false,
        userId,
        setAsDefault: false,
      });
      return;
    }
  }, [open, isSelectAE, userDetails, userPreferenceFieldName, showUsers]);

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Passing to {role}</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to move the card from {capitalize(fromStage)}{' '}
            to {capitalize(toStage)}?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div>
          <label
            htmlFor="stage-select"
            className="text-label-md font-bold mr-3"
          >
            Assign to {role}
          </label>
          <select
            id="user-select"
            ref={selectRef}
            onChange={handleSelectChange}
            value={selectedOption.userId?.toString()}
          >
            <option value="0" disabled>
              Select an {role}
            </option>
            {salesPipeline && !isSelectAE && (
              <option value="-1">Original Owner (Default)</option>
            )}

            {salesPipeline && isSelectAE && (
              <option value="-1">AE Bypass</option>
            )}
            {showUsers?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
                {' - '}
                {user.isSuperuser ? 'Admin' : user.userType}
              </option>
            ))}
          </select>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onCancel?.()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={!selectedOption.userId || selectedOption.loading}
            onClick={async () => {
              try {
                if (salesPipeline && selectedOption.userId) {
                  const isSelectedOriginalOwner = selectedOption.userId === -1;
                  const userId = selectedOption.userId;
                  await onConfirm?.(selectedOption.userId);

                  updateUserDetails({
                    ...userDetails,
                    [userPreferenceFieldName]: isSelectedOriginalOwner
                      ? null
                      : userId,
                  });
                  await updateProfile({
                    form: {
                      [userPreferenceFieldName]: isSelectedOriginalOwner
                        ? null
                        : userId,
                    },
                  });
                }
              } catch (e) {
                toast({
                  variant: 'destructive',
                  description: (e as Error).message,
                  title: 'Failed to Update Stage',
                });
              } finally {
                setSelectedOption((prev) => ({
                  ...prev,
                  loading: false,
                }));
              }
            }}
          >
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
