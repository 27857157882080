import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';

interface CheckboxWithLabelProps {
  id?: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  normalCase?: boolean;
  onClick?: () => void;
  onChange?: (value: boolean) => void;
  vertical?: boolean;
}

export const CheckboxWithLabel = ({
  id,
  label,
  checked,
  normalCase,
  onClick,
  disabled,
  onChange,
  vertical,
}: CheckboxWithLabelProps) => {
  return (
    <div
      className={cn('flex items-center', {
        'flex-col-reverse justify-center gap-1': vertical,
        'space-x-2': !vertical,
      })}
    >
      <Checkbox
        id={id ?? label}
        checked={checked}
        onClick={() => {
          onChange?.(!checked);
          onClick?.();
        }}
        disabled={disabled}
      />
      <Label
        htmlFor={id ?? label}
        className={
          checked
            ? `text-label-md text-dark-shade ${normalCase ? 'normal-case' : 'capitalize'}`
            : `text-paragraph-md text-dark-dark  ${normalCase ? 'normal-case' : 'capitalize'}`
        }
      >
        {label}
      </Label>
    </div>
  );
};
