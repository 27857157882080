import { AlertCircle } from 'lucide-react';
import { Loader, MarketOpportunityChart } from '@/components';
import { MarketOpportunityFilter } from '@/components/charts/market-opportunity-filter';
import { useQuery } from '@tanstack/react-query';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useApi } from '@/hooks/use-api';
import { StateStatsData } from '@/pages/market/market/types';
import { MarketStats } from '@/pages/market/market/market-stats';
import { stateMap } from '@/utils';
import {
  defaultAprRange,
  defaultBuildYearRange,
  defaultMortgageBalanceRange,
  defaultSaleDateRange,
} from '@/components/filters/sections/constants';
import { Button } from '@/components/ui/button';
import { MarketFilter } from '@/components/market/assign-leads-dialog/type';
import { AssignLeadsDialogContainer } from '@/pages/market/market/assign-leads-dialog-container';
import { generateQueryParams } from '@/pages/market/market/utils';
import { useAuth } from '@/context';
import { PlusIcon } from '@/components/ui/icons/plus-icon';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';

interface MarketContainerProps {
  updateMarketFilter?: (filter: Partial<MarketFilterState>) => void;
  defaultFilter?: Partial<MarketFilter>;
}
export interface MarketFilterState extends MarketFilter {}

export const MarketContainer = ({
  defaultFilter,
  updateMarketFilter,
}: MarketContainerProps) => {
  const { getRequest } = useApi();
  const { userDetails } = useAuth();
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [state, setState] = useState<MarketFilterState>({
    selectedAprRange: defaultAprRange,
    selectedMortgageBalanceRange: defaultMortgageBalanceRange,
    selectedBuildYearRange: defaultBuildYearRange,
    selectedSaleDateRange: defaultSaleDateRange,
    selectedStates: [],
    selectedMortgageTypes: [
      'Conventional',
      'Federal Housing Administration',
      'Veterans Affairs',
    ],
    selectedRankings: [-1, 4, 3, 2],
    selectedCallableOwners: [1, 2],
    selectedEmailableOwners: [0, 1, 2],
    selectedDoNotMails: [true, false],
    ...(defaultFilter ?? {}),
    ...(defaultFilter?.selectedSaleDateRange
      ? {
          selectedSaleDateRange: {
            from: new Date(
              defaultFilter?.selectedSaleDateRange?.from ??
                defaultSaleDateRange.from.toISOString()
            ),
            to: new Date(
              defaultFilter?.selectedSaleDateRange?.to ??
                defaultSaleDateRange.to.toISOString()
            ),
          },
        }
      : {}),
  });

  const deferredState = useDeferredValue(state);

  const { isPending, error, data, refetch } = useQuery<StateStatsData[]>({
    queryKey: ['properties', 'stats', deferredState],
    queryFn: () => {
      const queryParams = generateQueryParams(deferredState);
      return getRequest(`/api/properties/stats?${queryParams}`);
    },
  });

  useEffect(() => {
    void refetch();
  }, [deferredState, refetch]);

  useEffect(() => {
    updateMarketFilter?.(deferredState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredState]);

  const selectedData = useMemo(() => {
    return (
      data?.filter((stats) => {
        return state.selectedStates.includes(stats.state);
      }) ?? []
    );
  }, [data, state.selectedStates]);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div
      className="flex w-full space-x-5"
      style={{ height: 'calc(100vh - 108px)' }}
    >
      <div className="min-w-[280px] rounded-lg shadow bg-white">
        <MarketOpportunityFilter
          selectedSaleDateRange={state.selectedSaleDateRange}
          onSelectSaleDateRange={(selectedSaleDateRange) => {
            setState((prev) => ({ ...prev, selectedSaleDateRange }));
          }}
          selectedMortgageBalanceRange={state.selectedMortgageBalanceRange}
          onSelectMortgageBalanceRange={(selectedMortgageBalanceRange) => {
            setState((prev) => ({ ...prev, selectedMortgageBalanceRange }));
          }}
          selectedAprRange={state.selectedAprRange}
          onSelectAprRange={(selectedAprRange) => {
            setState((prev) => ({ ...prev, selectedAprRange }));
          }}
          selectedStates={state.selectedStates}
          onSelectStates={(selectedStates) => {
            setState((prev) => ({ ...prev, selectedStates }));
          }}
          selectedMortgageTypes={state.selectedMortgageTypes}
          onSelectMortgageTypes={(selectedMortgageTypes) => {
            setState((prev) => ({ ...prev, selectedMortgageTypes }));
          }}
          selectedRankings={state.selectedRankings}
          onSelectRankings={(selectedRankings) => {
            setState((prev) => ({ ...prev, selectedRankings }));
          }}
          selectedCallableOwners={state.selectedCallableOwners}
          onSelectCallableOwners={(selectedCallableOwners) => {
            setState((prev) => ({ ...prev, selectedCallableOwners }));
          }}
          selectedBuildYearRange={state.selectedBuildYearRange}
          onSelectBuildYearRange={(selectedBuildYearRange) => {
            setState((prev) => ({ ...prev, selectedBuildYearRange }));
          }}
          selectedEmailableOwners={state.selectedEmailableOwners}
          onSelectEmailableOwners={(selectedEmailableOwners) => {
            setState((prev) => ({ ...prev, selectedEmailableOwners }));
          }}
          selectedDoNotMails={state.selectedDoNotMails}
          onSelectDoNotMails={(selectedDoNotMails) => {
            setState((prev) => ({
              ...prev,
              selectedDoNotMails: selectedDoNotMails,
            }));
          }}
        />
      </div>

      <div className="w-full space-y-3 h-full overflow-y-auto bg-gradient-to-b from-[#e5e7ee] to-[#e5e7ee] rounded-lg p-3">
        <div className="flex justify-between">
          <div className="grid gap-4 grid-cols-1 lg:gap-8 xl:grid-cols-3">
            <MarketStats data={selectedData} loading={isPending} />
          </div>
          <div className="flex xl:justify-end">
            <Button
              className="space-x-2"
              disabled={isPending || selectedData.length === 0}
              onClick={() => setOpenAssignDialog(true)}
            >
              <PlusIcon className="h-3 w-3" />
              <span>Assign Leads</span>
            </Button>
          </div>
        </div>

        <FilterContainer
          value="market-opportunity-refinance-market-size"
          triggerClassName="bg-light-light rounded-lg shadow mt-3"
          title={
            <div className="text-heading-05 text-dark-dark">
              Market Opportunity - Refinance Market Size
            </div>
          }
        >
          <div className="pt-6 pb-12 space-y-6">
            {isPending || !selectedData ? (
              <div className="min-h-[1000px]">
                <Loader />
              </div>
            ) : (
              [
                'Conventional',
                'Federal Housing Administration',
                'Veterans Affairs',
              ].map((mortgageType) => (
                <div key={mortgageType}>
                  <MarketOpportunityChart
                    stats={selectedData
                      .filter((data) => data.mortgageType === mortgageType)
                      .map((stateStatsData) => ({
                        state:
                          stateMap[stateStatsData.state] ??
                          stateStatsData.state,
                        quartiles: stateStatsData.quartiles.map((quartile) => ({
                          count: quartile.count,
                          value: quartile.total,
                        })),
                      }))}
                    valueTitle={
                      <>
                        REFINANCEABLE MORTGAGE
                        <br />
                        PORTFOLIO VALUE
                      </>
                    }
                    title="Refinance Market Size"
                    algorithm={mortgageType}
                  />
                </div>
              ))
            )}
          </div>
        </FilterContainer>

        <FilterContainer
          value="market-opportunity-refinance-market-size"
          triggerClassName="bg-light-light rounded-lg shadow"
          title={
            <div className="text-heading-05 text-dark-dark">
              Market Opportunity - Refinance Market Demographics
            </div>
          }
        >
          <div className="pt-6 space-y-6">
            {isPending || !selectedData ? (
              <div className="min-h-[1000px]">
                <Loader />
              </div>
            ) : (
              [
                'Conventional',
                'Federal Housing Administration',
                'Veterans Affairs',
              ].map((mortgageType) => (
                <div key={mortgageType}>
                  <MarketOpportunityChart
                    stats={selectedData
                      .filter((data) => data.mortgageType === mortgageType)
                      .map((stateStatsData) => ({
                        state:
                          stateMap[stateStatsData.state] ??
                          stateStatsData.state,
                        quartiles: stateStatsData.quartiles.map((quartile) => ({
                          count: quartile.count,
                          value: quartile.total / quartile.count,
                        })),
                      }))}
                    valueTitle={
                      <>
                        AVERAGE REFINANCEABLE
                        <br />
                        MORTGAGE BALANCE
                      </>
                    }
                    title="Refinance Market Demographics"
                    algorithm={`${mortgageType} (${MORTGAGE_TYPE_MAP[mortgageType]})`}
                  />
                </div>
              ))
            )}
          </div>
        </FilterContainer>
      </div>
      {openAssignDialog && (
        <AssignLeadsDialogContainer
          isAdmin={userDetails?.isSuperuser ?? false}
          filter={state}
          open={openAssignDialog}
          onClose={() => setOpenAssignDialog(false)}
          refreshMarket={() => refetch()}
          currentUserEmail={userDetails.email}
        />
      )}
    </div>
  );
};
