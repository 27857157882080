interface TriStateToggleProps {
  value: boolean | null;
  onChange?: (value: boolean | null) => void;
  trueLabel?: string;
  falseLabel?: string;
}

const CheckmarkIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
  </svg>
);

const UncheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
      fill="currentColor"
    />
  </svg>
);

const DotIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 24 24"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
  </svg>
);

export const TriStateToggle = (props: TriStateToggleProps) => {
  const valueString = props.value?.toString() ?? 'na';

  return (
    <div>
      <div className="switch-wrapper">
        <input type="radio" checked={valueString === 'no'} />
        <input type="radio" checked={valueString === 'na'} />
        <input type="radio" checked={valueString === 'yes'} />
        <label className="no" onClick={() => props.onChange?.(false)}>
          <UncheckmarkIcon />
        </label>
        <label className="unavailable" onClick={() => props.onChange?.(null)}>
          <DotIcon />
        </label>
        <label className="yes" onClick={() => props.onChange?.(true)}>
          <CheckmarkIcon />
        </label>
        <span className={`highlighter ${valueString}`}></span>
      </div>
    </div>
  );
};
