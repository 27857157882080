import { ColumnDef } from '@tanstack/react-table';
import { moneyFormatter } from '@/utils/formaters';
import { capitalize } from '@/utils/string';
import { stateMap } from '@/utils/address';
import { calculateCommission } from '@/utils/commission';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { getPtDatetimeFormattedString } from '@/utils/date';
import { Property } from '@/pages/admin/property-data/property-table';
import { Checkbox } from '@/components/ui/checkbox';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';

export const MORTGAGE_TYPE_MAP: Record<string, string> = {
  CONVENTIONAL: 'CNV',
  'FEDERAL HOUSING ADMINISTRATION': 'FHA',
  'VETERANS AFFAIRS': 'VA',
};

export const getEstCurrentAnnualMortgageExpense = (
  record?: Property
): number => {
  return (record?.estimatedMonthlyMortgagePayment ?? 0) * 12;
};

export const getEstNewAnnualWholesaleMortgagePayment = (
  record?: Property,
  appSetting?: AppSettingResponse
): number => {
  const loadType =
    MORTGAGE_TYPE_MAP[record?.originalMortgageType?.toUpperCase() ?? ''] ??
    record?.originalMortgageType;

  let interestRate = 0;
  if (loadType === 'CNV') {
    interestRate = appSetting?.interestRateWholesaleCnv ?? 0;
  } else if (loadType === 'FHA') {
    interestRate = appSetting?.interestRateWholesaleFha ?? 0;
  } else if (loadType === 'VA') {
    interestRate = appSetting?.interestRateWholesaleVa ?? 0;
  }

  const estimatedMortgageBalance = record?.estimatedMortgageBalance ?? 0;

  return estimatedMortgageBalance * interestRate * 0.01;
};

export const getEstNewMonthlyWholesaleMortgagePayment = (
  record?: Property,
  appSetting?: AppSettingResponse
): number => {
  return getEstNewAnnualWholesaleMortgagePayment(record, appSetting) / 12;
};

export const getEstMonthlySavingsFromRefinancing = (
  record?: Property,
  appSetting?: AppSettingResponse
): number => {
  return (
    (record?.estimatedMonthlyMortgagePayment ?? 0) -
    getEstNewMonthlyWholesaleMortgagePayment(record, appSetting)
  );
};

export const getEstAnnualSavingsFromRefinancing = (
  record?: Property,
  appSetting?: AppSettingResponse
): number => {
  return (
    (record?.estimatedMonthlyMortgagePayment ?? 0) * 12 -
    getEstNewAnnualWholesaleMortgagePayment(record, appSetting)
  );
};

export const createColumns = (
  selectedRowIds: number[],
  setSelectedRowIds: (
    value: ((prevState: number[]) => number[]) | number[]
  ) => void,
  viewOnly: boolean,
  appSetting?: AppSettingResponse
): ColumnDef<Property>[] => {
  const columns: ColumnDef<Property>[] = [
    {
      id: 'select',
      meta: {
        title: 'Select',
        source: '',
      },
      // header: ({ table }) => {
      //   const isAllPageRowsSelected = table
      //     .getRowModel()
      //     .rows.every((row) => selectedRowIds.includes(row.original.id));
      //   return (
      //     <Checkbox
      //       checked={isAllPageRowsSelected}
      //       onClick={() => {
      //         if (isAllPageRowsSelected) {
      //           setSelectedRowIds((prev) =>
      //             prev.filter(
      //               (id) =>
      //                 !table
      //                   .getRowModel()
      //                   .rows.some((row) => row.original.id === id)
      //             )
      //           );
      //         } else {
      //           setSelectedRowIds((prev) => [
      //             ...prev,
      //             ...table
      //               .getRowModel()
      //               .rows.map((row) => row.original.id)
      //               .filter((id) => !prev.includes(id)),
      //           ]);
      //         }
      //       }}
      //     />
      //   );
      // },
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRowIds.includes(row.original.id)}
          onClick={() => {
            const isSelected = selectedRowIds.includes(row.original.id);
            if (isSelected) {
              setSelectedRowIds((prev) =>
                prev.filter((u) => u !== row.original.id)
              );
            } else {
              setSelectedRowIds((prev) => [...prev, row.original.id]);
            }
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'dateItem',
      accessorFn: () => '',
      accessorKey: 'dateItem',
      meta: {
        title: 'Data Item',
        source: 'Data Source',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: () => <div className="w-[100px]"></div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'id',
      accessorKey: 'id',
      meta: {
        title: 'Id',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div className="w-[100px]">{row.getValue('id')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'apn',
      meta: {
        title: 'APN',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div className="w-[120px]">{row.getValue('apn')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'fips',
      meta: {
        title: 'FIPS',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[200px] truncate">{row.getValue('fips')}</span>
        );
      },
    },
    {
      accessorKey: 'state',
      meta: {
        title: 'State',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const state = row.getValue('state') as string;

        return (
          <span className="max-w-[120px] truncate uppercase">
            {stateMap[state] ?? state}
          </span>
        );
      },
    },
    {
      accessorKey: 'county',
      meta: {
        title: 'County',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[120px] truncate">
            {row.getValue('county')}
          </span>
        );
      },
    },
    {
      accessorKey: 'situsCity',
      meta: {
        title: 'City',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[120px] truncate">
            {row.getValue('situsCity')}
          </span>
        );
      },
    },
    {
      accessorKey: 'situsStreetAddress',
      meta: {
        title: 'Situs Street Address',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[120px] truncate">
            {row.getValue('situsStreetAddress')}
          </span>
        );
      },
    },
    {
      accessorKey: 'zipCode',
      meta: {
        title: 'Zip Code',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[120px] truncate">
            {row.getValue('zipCode')}
          </span>
        );
      },
    },
    {
      accessorKey: 'situsFullAddress',
      meta: {
        title: 'Situs Address',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[300px] truncate">
            {row.getValue('situsFullAddress')}
          </span>
        );
      },
    },
    {
      accessorKey: 'bedrooms',
      meta: {
        title: 'Bedrooms',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[300px] truncate">
            {row.getValue('bedrooms')}
          </span>
        );
      },
    },
    {
      accessorKey: 'bathrooms',
      meta: {
        title: 'Bathrooms',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[300px] truncate">
            {row.getValue('bathrooms')}
          </span>
        );
      },
    },
    {
      accessorKey: 'homeSqft',
      meta: {
        title: 'Home Size (SQ FT)',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[300px] truncate">
            {row.getValue('homeSqft')}
          </span>
        );
      },
    },
    {
      accessorKey: 'yearBuilt',
      meta: {
        title: 'Build Year',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[300px] truncate">
            {row.getValue('yearBuilt')}
          </span>
        );
      },
    },
    {
      accessorKey: 'originalMortgageType',
      meta: {
        title: 'Mortgage Type',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {MORTGAGE_TYPE_MAP[
              (row.getValue('originalMortgageType') as string)?.toUpperCase()
            ] ?? row.getValue('originalMortgageType')}
          </span>
        );
      },
    },
    {
      accessorKey: 'originalMortgageAmount',
      meta: {
        title: 'Purchase Mortgage Amount',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              parseFloat(row.getValue('originalMortgageAmount'))
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'originalMortgageInterestRate',
      meta: {
        title: 'Est. Purchase Mortgage Interest Rate',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('originalMortgageInterestRate')}%
          </span>
        );
      },
    },
    {
      id: 'refinanceCommission',
      accessorFn: (row) =>
        calculateCommission(
          parseFloat(row.originalMortgageAmount?.toString() ?? '0.0')
        ),
      accessorKey: 'refinanceCommission',
      meta: {
        title: 'Est. Refinance Commission',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(row.getValue('refinanceCommission'))}
          </span>
        );
      },
    },
    {
      accessorKey: 'propertySalePrice',
      meta: {
        title: 'Last Arms-Length Sale Price',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              parseFloat(row.getValue('propertySalePrice'))
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'recordDateLastArmsLengthSale',
      meta: {
        title: 'Last Arms-length Sale Date',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('recordDateLastArmsLengthSale')}
          </span>
        );
      },
    },
    {
      accessorKey: 'lenderCompetitivenessRanking',
      meta: {
        title: 'Lender Competitiveness Ranking',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('lenderCompetitivenessRanking') === -1
              ? 'Unassigned'
              : row.getValue('lenderCompetitivenessRanking')}
          </span>
        );
      },
      sortingFn: 'alphanumeric',
    },
    {
      accessorKey: 'lenderCount',
      meta: {
        title: 'Lender Property Count',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('lenderCount')}
          </span>
        );
      },
    },
    {
      accessorKey: 'timezone',
      meta: {
        title: 'Timezone',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return <span>{row.getValue('timezone')}</span>;
      },
    },
    {
      accessorKey: 'originalMortgageLender',
      meta: {
        title: 'Purchase Mortgage Lender',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('originalMortgageLender')}
          </span>
        );
      },
    },
    {
      id: 'firstOwner',
      accessorFn: (row) =>
        `${row.firstCurrentOwnerFirstName} ${row.firstCurrentOwnerLastName}`.trim(),
      meta: {
        title: 'First Owner',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwner')}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerEmail',
      meta: {
        title: 'First Owner Email',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstCurrentOwnerEmail') === null ? (
              'Unavailable'
            ) : (
              <a
                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                href={`mailto:${row.getValue('firstCurrentOwnerEmail')}`}
              >
                {row.getValue('firstCurrentOwnerEmail')}
              </a>
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerEmailStatus',
      meta: {
        title: 'First Owner Email Status',
        source: '[EMAIL VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => (
        <span className="max-w-[500px] truncate">
          {row.getValue('firstCurrentOwnerEmailStatus')}
        </span>
      ),
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneNumber',
      meta: {
        title: 'First Owner Primary Phone',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstCurrentOwnerPhoneNumber')}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneIsConnected',
      meta: {
        title: 'First Owner Primary Phone Is Connected',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstCurrentOwnerPhoneIsConnected') === null
              ? ''
              : row.getValue('firstCurrentOwnerPhoneIsConnected')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneType',
      meta: {
        title: 'First Owner Primary Phone Type',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {capitalize(row.getValue('firstCurrentOwnerPhoneType'))}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncConnectionStatus',
      meta: {
        title: 'First Owner Primary Phone Connection Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncConnectionStatus') === null
              ? ''
              : (
                    row.getValue('firstOwnerDncConnectionStatus') as string
                  ).includes('disconnected')
                ? 'Disconnected'
                : 'Connected'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncIsCell',
      meta: {
        title: 'First Owner Primary Phone Is Cell',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncIsCell') === null
              ? ''
              : row.getValue('firstOwnerDncIsCell')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncNationalDnc',
      meta: {
        title: 'First Owner Primary Phone National DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncNationalDnc') === null
              ? ''
              : row.getValue('firstOwnerDncNationalDnc')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncStateDnc',
      meta: {
        title: 'First Owner Primary Phone State DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncStateDnc') === null
              ? ''
              : row.getValue('firstOwnerDncStateDnc')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncDma',
      meta: {
        title: 'First Owner Primary Phone DMA Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncDma') === null
              ? ''
              : row.getValue('firstOwnerDncDma')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncLitigator',
      meta: {
        title: 'First Owner Primary Phone Known Litigator Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncLitigator') === null
              ? ''
              : row.getValue('firstOwnerDncLitigator')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncDncStatus',
      meta: {
        title: 'First Owner Primary Phone DNC Status',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncDncStatus') === null
              ? ''
              : row.getValue('firstOwnerDncDncStatus')}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneNumber2',
      meta: {
        title: 'First Owner Secondary Phone',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstCurrentOwnerPhoneNumber2')}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneIsConnected2',
      meta: {
        title: 'First Owner Secondary Phone Is Connected',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstCurrentOwnerPhoneIsConnected2') === null
              ? ''
              : row.getValue('firstCurrentOwnerPhoneIsConnected2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstCurrentOwnerPhoneType2',
      meta: {
        title: 'First Owner Secondary Phone Type',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {capitalize(row.getValue('firstCurrentOwnerPhoneType2'))}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncConnectionStatus2',
      meta: {
        title: 'First Owner Secondary Phone Connection Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncConnectionStatus2') === null
              ? ''
              : (
                    row.getValue('firstOwnerDncConnectionStatus2') as string
                  ).includes('disconnected')
                ? 'Disconnected'
                : 'Connected'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncIsCell2',
      meta: {
        title: 'First Owner Secondary Phone Is Cell',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncIsCell2') === null
              ? ''
              : row.getValue('firstOwnerDncIsCell2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncNationalDnc2',
      meta: {
        title: 'First Owner Secondary Phone National DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncNationalDnc2') === null
              ? ''
              : row.getValue('firstOwnerDncNationalDnc2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncStateDnc2',
      meta: {
        title: 'First Owner Secondary Phone State DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncStateDnc2') === null
              ? ''
              : row.getValue('firstOwnerDncStateDnc2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncDma2',
      meta: {
        title: 'First Owner Secondary Phone DMA Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncDma2') === null
              ? ''
              : row.getValue('firstOwnerDncDma2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncLitigator2',
      meta: {
        title: 'First Owner Secondary Phone Litigator Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncLitigator2') === null
              ? ''
              : row.getValue('firstOwnerDncLitigator2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'firstOwnerDncDncStatus2',
      meta: {
        title: 'First Owner Secondary Phone DNC Status',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('firstOwnerDncDncStatus2') === null
              ? ''
              : row.getValue('firstOwnerDncDncStatus2')}
          </span>
        );
      },
    },
    {
      id: 'secondOwner',
      accessorFn: (row) =>
        `${row.secondCurrentOwnerFirstName ?? ''} ${row.secondCurrentOwnerLastName ?? ''}`.trim(),
      meta: {
        title: 'Second Owner',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwner')}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerEmail',
      meta: {
        title: 'Second Owner Email',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondCurrentOwnerEmail') === null ? (
              'Unavailable'
            ) : (
              <a
                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                href={`mailto:${row.getValue('secondCurrentOwnerEmail')}`}
              >
                {row.getValue('secondCurrentOwnerEmail')}
              </a>
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerEmailStatus',
      meta: {
        title: 'Second Owner Email Status',
        source: '[EMAIL VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => (
        <span className="max-w-[500px] truncate">
          {row.getValue('secondCurrentOwnerEmailStatus')}
        </span>
      ),
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneNumber',
      meta: {
        title: 'Second Owner Primary Phone',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondCurrentOwnerPhoneNumber')}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneIsConnected',
      meta: {
        title: 'Second Owner Primary Phone Is Connected',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondCurrentOwnerPhoneIsConnected') === null
              ? ''
              : row.getValue('secondCurrentOwnerPhoneIsConnected')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneType',
      meta: {
        title: 'Second Owner Primary Phone Type',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {capitalize(row.getValue('secondCurrentOwnerPhoneType'))}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncConnectionStatus',
      meta: {
        title: 'Second Owner Primary Phone Connection Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncConnectionStatus') === null
              ? ''
              : (
                    row.getValue('secondOwnerDncConnectionStatus') as string
                  ).includes('disconnected')
                ? 'Disconnected'
                : 'Connected'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncIsCell',
      meta: {
        title: 'Second Owner Primary Phone Is Cell',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncIsCell') === null
              ? ''
              : row.getValue('secondOwnerDncIsCell')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncNationalDnc',
      meta: {
        title: 'Second Owner Primary Phone National DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncNationalDnc') === null
              ? ''
              : row.getValue('secondOwnerDncNationalDnc')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncStateDnc',
      meta: {
        title: 'Second Owner Primary Phone State DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncStateDnc') === null
              ? ''
              : row.getValue('secondOwnerDncStateDnc')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncDma',
      meta: {
        title: 'Second Owner Primary Phone DMA Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncDma') === null
              ? ''
              : row.getValue('secondOwnerDncDma')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncLitigator',
      meta: {
        title: 'Second Owner Primary Phone Litigator Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncLitigator') === null
              ? ''
              : row.getValue('secondOwnerDncLitigator')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncDncStatus',
      meta: {
        title: 'Second Owner Primary Phone DNC Status',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncDncStatus') === null
              ? ''
              : row.getValue('secondOwnerDncDncStatus')}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneNumber2',
      meta: {
        title: 'Second Owner Secondary Phone',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondCurrentOwnerPhoneNumber2')}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneIsConnected2',
      meta: {
        title: 'Second Owner Phone Secondary Is Connected',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondCurrentOwnerPhoneIsConnected2') === null
              ? ''
              : row.getValue('secondCurrentOwnerPhoneIsConnected2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondCurrentOwnerPhoneType2',
      meta: {
        title: 'Second Owner Secondary Phone Type',
        source: '[ENDATO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {capitalize(row.getValue('secondCurrentOwnerPhoneType2'))}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncConnectionStatus2',
      meta: {
        title: 'Second Owner Secondary Phone Connection Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncConnectionStatus2') === null
              ? ''
              : (
                    row.getValue('secondOwnerDncConnectionStatus2') as string
                  ).includes('disconnected')
                ? 'Disconnected'
                : 'Connected'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncIsCell2',
      meta: {
        title: 'Second Owner Secondary Phone Is Cell',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncIsCell2') === null
              ? ''
              : row.getValue('secondOwnerDncIsCell2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncNationalDnc2',
      meta: {
        title: 'Second Owner Secondary Phone National DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncNationalDnc2') === null
              ? ''
              : row.getValue('secondOwnerDncNationalDnc2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncStateDnc2',
      meta: {
        title: 'Second Owner Secondary Phone State DNC Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncStateDnc2') === null
              ? ''
              : row.getValue('secondOwnerDncStateDnc2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncDma2',
      meta: {
        title: 'Second Owner Secondary Phone DMA Registration',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncDma2') === null
              ? ''
              : row.getValue('secondOwnerDncDma2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncLitigator2',
      meta: {
        title: 'Second Owner Secondary Phone Litigator Status',
        source: '[REAL PHONE VALIDATOR]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncLitigator2') === null
              ? ''
              : row.getValue('secondOwnerDncLitigator2')
                ? 'Yes'
                : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'secondOwnerDncDncStatus2',
      meta: {
        title: 'Second Owner Secondary Phone DNC Status',
        source: '[ALGO]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('secondOwnerDncDncStatus2') === null
              ? ''
              : row.getValue('secondOwnerDncDncStatus2')}
          </span>
        );
      },
    },
    {
      accessorKey: 'callableOwners',
      meta: {
        title: 'Callable Owners',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('callableOwners')}
          </span>
        );
      },
    },
    {
      accessorKey: 'emailableOwners',
      meta: {
        title: 'Emailable Owners',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('emailableOwners')}
          </span>
        );
      },
    },
    {
      accessorKey: 'doNotMail',
      meta: {
        title: 'Property Do Not Mail Registration',
        source: '[FIRST AMERICAN]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('doNotMail') ? 'Yes' : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'federalFundsEffectiveRate',
      meta: {
        title: 'Fed Funds Rate',
        source: '[FRED]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('federalFundsEffectiveRate')}
          </span>
        );
      },
    },
    {
      accessorKey: 'thirtyYearFixedRateMortgageAverage',
      meta: {
        title: 'Avg 30 YR Mortgage Interest Rate',
        source: '[FRED]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('thirtyYearFixedRateMortgageAverage')}
          </span>
        );
      },
    },
    {
      accessorKey: 'estimatedMonthlyMortgagePayment',
      meta: {
        title: 'Est. Current Monthly Mortgage Payment',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('estimatedMonthlyMortgagePayment')
              ? moneyFormatter.format(
                  row.getValue('estimatedMonthlyMortgagePayment')
                )
              : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'estCurrentAnnualMortgageExpense',
      meta: {
        title: 'Est. Current Annual Mortgage Expense',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              getEstCurrentAnnualMortgageExpense(row.original)
            )}
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = getEstCurrentAnnualMortgageExpense(rowA.original);
        const valueB = getEstCurrentAnnualMortgageExpense(rowB.original);
        return valueA - valueB;
      },
    },
    {
      accessorKey: 'estNewMonthlyWholesaleMortgagePayment',
      meta: {
        title: 'Est. New Monthly Wholesale Mortgage Payment',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              getEstNewMonthlyWholesaleMortgagePayment(row.original, appSetting)
            )}
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = getEstNewMonthlyWholesaleMortgagePayment(
          rowA.original,
          appSetting
        );
        const valueB = getEstNewMonthlyWholesaleMortgagePayment(
          rowB.original,
          appSetting
        );
        return valueA - valueB;
      },
    },
    {
      accessorKey: 'estNewAnnualWholesaleMortgageExpense',
      meta: {
        title: 'Est. New Annual Wholesale Mortgage Expense',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              getEstNewAnnualWholesaleMortgagePayment(row.original, appSetting)
            )}
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = getEstNewAnnualWholesaleMortgagePayment(
          rowA.original,
          appSetting
        );
        const valueB = getEstNewAnnualWholesaleMortgagePayment(
          rowB.original,
          appSetting
        );
        return valueA - valueB;
      },
    },
    {
      accessorKey: 'estMonthlySavingsFromRefinancing',
      meta: {
        title: 'Est. Monthly Savings from Refinancing',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              getEstMonthlySavingsFromRefinancing(row.original, appSetting)
            )}
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = getEstMonthlySavingsFromRefinancing(
          rowA.original,
          appSetting
        );
        const valueB = getEstMonthlySavingsFromRefinancing(
          rowB.original,
          appSetting
        );
        return valueA - valueB;
      },
    },
    {
      accessorKey: 'estAnnualSavingsFromRefinancing',
      meta: {
        title: 'Est. Annual Savings from Refinancing',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {moneyFormatter.format(
              getEstAnnualSavingsFromRefinancing(row.original, appSetting)
            )}
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = getEstAnnualSavingsFromRefinancing(
          rowA.original,
          appSetting
        );
        const valueB = getEstAnnualSavingsFromRefinancing(
          rowB.original,
          appSetting
        );
        return valueA - valueB;
      },
    },
    {
      accessorKey: 'estimatedMortgageBalance',
      meta: {
        title: 'Est. Current Mortgage Balance',
        source: '[CALC]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate">
            {row.getValue('estimatedMortgageBalance')
              ? moneyFormatter.format(row.getValue('estimatedMortgageBalance'))
              : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'notes',
      meta: {
        title: 'Notes',
        source: '[CRM]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px] whitespace-pre-wrap">
            {row.getValue('notes') ?? ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'task',
      meta: {
        title: 'Task',
        source: '[CRM]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px] whitespace-pre-wrap">
            {row.getValue('task') ?? ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'stage',
      meta: {
        title: 'Lead Stage',
        source: '[CRM]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px]">{row.getValue('stage') ?? ''}</span>
        );
      },
    },
    {
      accessorKey: 'receptiveness',
      meta: {
        title: 'Owner Receptiveness Score',
        source: '[CRM]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px]">
            {row.getValue('receptiveness') ?? ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'assignedUserId',
      meta: {
        title: 'Assigned User Id',
        source: '[CRM]',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px]">
            {row.getValue('assignedUserId') ?? ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'lostReasonDescription',
      meta: {
        title: 'Lead Loss Reason',
        source: '[CRM]',
      },
      enableSorting: false,
      enableHiding: true,
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <span className="max-w-[100px]">
            {row.getValue('lostReasonDescription') ?? ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'faUpdatedAt',
      meta: {
        title: 'Data Pull Timestamp – FA',
        source: '[FIRST AMERICAN]',
      },
      enableSorting: false,
      enableHiding: true,
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const value = row.getValue('faUpdatedAt') as string | null;
        return (
          <span className="max-w-[100px]">
            {value ? getPtDatetimeFormattedString(value) : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'endatoUpdatedAt',
      meta: {
        title: 'Data Pull Timestamp – Endato',
        source: '[ENDATO]',
      },
      enableSorting: false,
      enableHiding: true,
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const value = row.getValue('endatoUpdatedAt') as string | null;
        return (
          <span className="max-w-[100px]">
            {value ? getPtDatetimeFormattedString(value) : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'rpvUpdatedAt',
      meta: {
        title: 'Data Pull Timestamp – RPV',
        source: '[REAL PHONE VALIDATOR]',
      },
      enableSorting: false,
      enableHiding: true,
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const value = row.getValue('rpvUpdatedAt') as string | null;
        return (
          <span className="max-w-[100px]">
            {value ? getPtDatetimeFormattedString(value) : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'fredUpdatedAt',
      meta: {
        title: 'Data Pull Timestamp – FRED',
        source: '[FRED]',
      },
      enableSorting: false,
      enableHiding: true,
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const value = row.getValue('fredUpdatedAt') as string | null;
        return (
          <span className="max-w-[100px]">
            {value ? getPtDatetimeFormattedString(value) : ''}
          </span>
        );
      },
    },
  ];

  // if viewOnly. remove the first column
  if (viewOnly) {
    columns.shift();
  }

  return columns;
};
