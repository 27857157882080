import type { EChartsOption } from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface TeamRefinancedChartRow {
  date: string;
  leadFollowUp: number;
  leadRefinancedAmount: number;
}

interface TeamRefinancedChartProps {
  data: TeamRefinancedChartRow[];
  boardType: BoardType;
}

export const TeamRefinancedChart = ({
  data,
  boardType,
}: TeamRefinancedChartProps) => {
  const lastStageName =
    boardType === 'LO' ? 'Refinanced' : 'Interested Parties';

  const option: EChartsOption = {
    xAxis: {
      type: 'category',
      data: data.map((row) => row.date),
      axisLabel: {
        align: 'left',
      },
    },
    yAxis: {
      type: 'value',
      name: `Aggregate Sum of \n${lastStageName} \nMortgage Balances`,
      axisLabel: {
        formatter: (value: number) => {
          if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M`;
          } else if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K`;
          }
          return value.toString();
        },
      },
    },
    series: [
      {
        name: `Aggregate Sum of \n${lastStageName} \nMortgage Balances`,
        type: 'bar',
        data: data.map((row) => row.leadRefinancedAmount),
        itemStyle: {
          color: '#056B3A',
        },
        tooltip: {
          valueFormatter: (value) => {
            if (typeof value === 'number') {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}M`;
              } else if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}K`;
              }
              return value.toString();
            }
            return value?.toString() ?? '';
          },
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data.length === 0 ? (
        <div style={{ height: 450 }}>Loading...</div>
      ) : (
        <ECharts option={option} style={{ height: 450 }} />
      )}
    </div>
  );
};
