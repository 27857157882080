export const DollarIcon = ({ className }: { className?: string }) => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect y="0.710449" width="36" height="36" rx="18" fill="#056B3A" />
    <path
      d="M25.1025 22.4819C25.1025 25.486 23.2086 27.086 19.3556 27.3472V30.7104H16.7433V27.3145C12.5964 26.8574 10.9637 24.5064 10.8984 21.4043H13.3148C13.5107 23.6247 14.6209 25.388 18.278 25.388C21.4127 25.388 22.5229 24.4084 22.5229 22.6125C22.5229 20.9472 21.6086 20.1309 19.5515 19.837L15.7637 19.2819C12.9229 18.89 11.3229 17.4207 11.3229 14.8737C11.3229 12.1962 13.3801 10.4002 16.7433 10.0411V6.71045H19.3556V10.0411C22.7842 10.4656 24.6127 12.4574 24.776 15.2002H22.1964C22.0331 13.5023 20.9556 11.9676 17.9515 11.9676C15.176 11.9676 13.8699 13.0778 13.8699 14.6778C13.8699 15.8207 14.4576 16.8002 16.5148 17.0941L20.2046 17.6166C23.7311 18.139 25.1025 19.7717 25.1025 22.4819Z"
      fill="#CEF0DF"
    />
  </svg>
);
