import { IconProps } from '@/components/ui/icons/types';

export const ContactIcon = ({ className }: IconProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="48" height="48" rx="24" fill="#FFA53C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 14C14.5 12.3431 15.8431 11 17.5 11H33.5C35.1569 11 36.5 12.3431 36.5 14V34C36.5 35.6569 35.1569 37 33.5 37H17.5C15.8431 37 14.5 35.6569 14.5 34V32H11.5V28H14.5V26H11.5V22H14.5V20H11.5V16H14.5V14ZM29.5 19C29.5 16.8013 27.6973 15 25.5 15C23.304 15 21.5 16.8013 21.5 19C21.5 21.1973 23.304 23.0013 25.5 23.0013C26.56 22.9978 27.5756 22.5751 28.325 21.8254C29.0744 21.0757 29.4968 20.06 29.5 19ZM33.5 32.3347V31.3347C33.5 28.376 29.8933 25.3347 25.5 25.3347C21.1067 25.3347 17.5 28.376 17.5 31.3347V32.3347H33.5Z"
      fill="currentColor"
    />
  </svg>
);
