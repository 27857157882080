import { EmailIcon, PhoneIcon, UserIcon } from '@/components/icons';
import { OwnerItem } from '@/components/map/property-details/owner-item';
import { EditContactDialog } from '@/components/map/property-details/dialogs/edit-contact-dialog';
import { Owner, OwnerForm } from '@/components/map/property-details/types';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { useCallback, useState } from 'react';
import { TriStateToggle } from '@/pages/crm/lead-stages/tri-state-toggle/tri-state-toggle';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { useApi } from '@/hooks/use-api';

interface OwnerDetailsProps {
  title: string;
  owner: Owner;
  loading?: boolean;
  salesPipeline?: SalesPipeline;
  position: 1 | 2;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
  refetchOutboundCalls?(): void;
}

export const OwnerDetails = ({
  title,
  owner,
  position,
  loading,
  salesPipeline,
  updateSalesPipelineOwner,
  updateSalesPipeline,
  refetchOutboundCalls,
}: OwnerDetailsProps) => {
  const { postRequest } = useApi();
  const [lastCallTime, setLastCallTime] = useState<number | null>(null);

  const handleCallClick = useCallback(
    async (phoneNumber: string, ownerName: string) => {
      const sanitizedPhoneNumber = phoneNumber.replace(/[^\d+]/g, '');
      const now = Date.now();
      if (lastCallTime === null || now - lastCallTime > 5000) {
        setLastCallTime(now);
        window.location.href = `tel:${sanitizedPhoneNumber}`;
      } else {
        alert('Please wait 5 seconds before making another call.');
      }

      await postRequest(
        `/api/sales_pipelines/${salesPipeline?.id}/outbound_calls`,
        { phoneNumber, ownerName }
      );

      refetchOutboundCalls?.();
    },
    [lastCallTime, postRequest, refetchOutboundCalls, salesPipeline?.id]
  );

  return (
    <>
      <div className="flex items-center space-x-3">
        <h2 className="text-dark-dark text-heading-05 py-4">{title}</h2>
        {salesPipeline && (
          <EditContactDialog
            owner={owner}
            position={position}
            updateSalesPipelineOwner={updateSalesPipelineOwner}
          />
        )}
      </div>
      <ul>
        <OwnerItem
          value={
            owner.firstName ? `${owner.firstName} ${owner.lastName}` : 'N/A'
          }
          icon={<UserIcon />}
          loading={loading}
        />
        {owner.phoneNumber && (
          <OwnerItem
            value={
              owner.dncStatus === 'DNC' ? (
                'DNC'
              ) : (
                <div className="flex space-x-2 justify-between items-center">
                  {owner.phoneNumber === 'Unavailable' ? (
                    <div>{owner.phoneNumber}</div>
                  ) : (
                    <button
                      onClick={() =>
                        handleCallClick(
                          owner.phoneNumber as string,
                          owner.firstName
                            ? `${owner.firstName} ${owner.lastName}`
                            : 'N/A'
                        )
                      }
                    >
                      {owner.phoneNumber}
                    </button>
                  )}
                  {owner.phoneNumber !== 'Unavailable' && (
                    <div className="flex space-x-4 items-center">
                      <TriStateToggle
                        value={
                          (position === 1
                            ? salesPipeline?.isFirstOwnerPhoneValid
                            : salesPipeline?.isSecondOwnerPhoneValid) ?? null
                        }
                        trueLabel="Valid"
                        falseLabel="Faulty"
                        onChange={(value) =>
                          updateSalesPipeline?.(salesPipeline!.id, {
                            [position === 1
                              ? 'isFirstOwnerPhoneValid'
                              : 'isSecondOwnerPhoneValid']: value,
                          })
                        }
                      />
                      <CheckboxWithLabel
                        id={`pn-${position}-1`}
                        label="PN"
                        vertical
                        checked={
                          salesPipeline?.preferNumberPosition ===
                          position * 2 - 1
                        }
                        onChange={(checked) => {
                          updateSalesPipeline?.(salesPipeline!.id, {
                            preferNumberPosition: checked
                              ? position * 2 - 1
                              : null,
                          });
                        }}
                      />
                      <CheckboxWithLabel
                        id={`vm-${position}-1`}
                        label="VM"
                        vertical
                        checked={
                          (position === 1
                            ? salesPipeline?.isFirstOwnerPhoneVoicemail
                            : salesPipeline?.isSecondOwnerPhoneVoicemail) ??
                          false
                        }
                        onChange={(value) => {
                          updateSalesPipeline?.(salesPipeline!.id, {
                            [position === 1
                              ? 'isFirstOwnerPhoneVoicemail'
                              : 'isSecondOwnerPhoneVoicemail']: value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            }
            icon={<PhoneIcon />}
            loading={loading}
          />
        )}
        {owner.phoneNumber2 && (
          <OwnerItem
            value={
              owner.dncStatus2 === 'DNC' ? (
                'DNC'
              ) : (
                <div className="flex space-x-2 justify-between items-center">
                  {owner.phoneNumber2 === 'Unavailable' ? (
                    <div>{owner.phoneNumber2}</div>
                  ) : (
                    <button
                      onClick={() =>
                        handleCallClick(
                          owner.phoneNumber2 as string,
                          owner.firstName
                            ? `${owner.firstName} ${owner.lastName}`
                            : 'N/A'
                        )
                      }
                    >
                      {owner.phoneNumber2}
                    </button>
                  )}

                  {owner.phoneNumber2 !== 'Unavailable' && (
                    <div className="flex space-x-4 items-center">
                      <TriStateToggle
                        value={
                          (position === 1
                            ? salesPipeline?.isFirstOwnerPhone2Valid
                            : salesPipeline?.isSecondOwnerPhone2Valid) ?? null
                        }
                        trueLabel="Valid"
                        falseLabel="Faulty"
                        onChange={(value) =>
                          updateSalesPipeline?.(salesPipeline!.id, {
                            [position === 1
                              ? 'isFirstOwnerPhone2Valid'
                              : 'isSecondOwnerPhone2Valid']: value,
                          })
                        }
                      />
                      <CheckboxWithLabel
                        id={`pn-${position}-2`}
                        label="PN"
                        vertical
                        checked={
                          salesPipeline?.preferNumberPosition === position * 2
                        }
                        onChange={(checked) => {
                          updateSalesPipeline?.(salesPipeline!.id, {
                            preferNumberPosition: checked ? position * 2 : null,
                          });
                        }}
                      />
                      <CheckboxWithLabel
                        id={`vm-${position}-2`}
                        label="VM"
                        vertical
                        checked={
                          (position === 1
                            ? salesPipeline?.isFirstOwnerPhone2Voicemail
                            : salesPipeline?.isSecondOwnerPhone2Voicemail) ??
                          false
                        }
                        onChange={(value) => {
                          updateSalesPipeline?.(salesPipeline!.id, {
                            [position === 1
                              ? 'isFirstOwnerPhone2Voicemail'
                              : 'isSecondOwnerPhone2Voicemail']: value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            }
            icon={<PhoneIcon />}
            loading={loading}
          />
        )}
        {owner.email && (
          <OwnerItem
            value={<a href={`mailto:${owner.email}`}>{owner.email}</a>}
            icon={<EmailIcon />}
            loading={loading}
          />
        )}
      </ul>
    </>
  );
};
