import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';

interface ActivityFilterDropdownProps {
  value?: number;
  onValueChange: (value: number | undefined) => void;
}

interface Option {
  value?: number;
  label: string;
}

const FILTER_OPTIONS: Option[] = [
  { label: 'All' },
  { value: 7, label: '7 Days' },
  { value: 14, label: '14 Days' },
  { value: 30, label: '30 Days' },
  { value: 60, label: '60 Days' },
  { value: 90, label: '90 Days' },
  { value: 365, label: '365 Days' },
];

export const ActivityFilterDropdown = ({
  value,
  onValueChange,
}: ActivityFilterDropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="blue">
          <span>
            {FILTER_OPTIONS.find((option) => option.value === value)?.label}
          </span>
          <span>
            <DropdownButtonIcon />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade max-h-80 overflow-y-auto">
        <DropdownMenuGroup>
          {FILTER_OPTIONS.map((option) => (
            <DropdownMenuCheckboxItem
              checked={option.value === value}
              key={option.label}
              className="focus:text-blue-tint focus:bg-blue-blue"
              onClick={() => onValueChange(option.value)}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
