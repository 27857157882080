import { IconProps } from '@/components/ui/icons/types';

export const CupIcon = ({ className }: IconProps) => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect y="0.710449" width="36" height="36" rx="18" fill="#05597C" />
    <path
      d="M27.998 14.8736V14.9466C27.998 15.8066 27.998 16.2376 27.791 16.5896C27.584 16.9416 27.207 17.1506 26.455 17.5696L25.662 18.0096C26.208 16.1616 26.391 14.1756 26.458 12.4776L26.468 12.2566L26.47 12.2046C27.121 12.4306 27.487 12.5996 27.715 12.9156C27.998 13.3086 27.998 13.8306 27.998 14.8736ZM7.99805 14.8736V14.9466C7.99805 15.8066 7.99805 16.2376 8.20505 16.5896C8.41205 16.9416 8.78905 17.1506 9.54105 17.5696L10.335 18.0096C9.78805 16.1616 9.60505 14.1756 9.53805 12.4776L9.52805 12.2566L9.52705 12.2046C8.87505 12.4306 8.50905 12.5996 8.28105 12.9156C7.99805 13.3086 7.99805 13.8316 7.99805 14.8736Z"
      fill="#CEEAF5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3735 9.05816C20.9266 8.82116 19.4626 8.70509 17.9965 8.71116C16.2135 8.71116 14.7435 8.86816 13.6195 9.05816C12.4805 9.25016 11.9115 9.34616 11.4355 9.93216C10.9605 10.5182 10.9855 11.1512 11.0355 12.4172C11.2085 16.7652 12.1465 22.1972 17.2465 22.6772V26.2112H15.8165C15.5854 26.2113 15.3615 26.2915 15.1828 26.4381C15.0041 26.5846 14.8818 26.7886 14.8365 27.0152L14.6465 27.9612H11.9965C11.7976 27.9612 11.6068 28.0402 11.4661 28.1808C11.3255 28.3215 11.2465 28.5123 11.2465 28.7112C11.2465 28.9101 11.3255 29.1008 11.4661 29.2415C11.6068 29.3821 11.7976 29.4612 11.9965 29.4612H23.9965C24.1954 29.4612 24.3861 29.3821 24.5268 29.2415C24.6674 29.1008 24.7465 28.9101 24.7465 28.7112C24.7465 28.5123 24.6674 28.3215 24.5268 28.1808C24.3861 28.0402 24.1954 27.9612 23.9965 27.9612H21.3465L21.1565 27.0152C21.1112 26.7886 20.9888 26.5846 20.8101 26.4381C20.6315 26.2915 20.4076 26.2113 20.1765 26.2112H18.7465V22.6772C23.8465 22.1972 24.7855 16.7662 24.9575 12.4172C25.0075 11.1512 25.0335 10.5172 24.5575 9.93216C24.0815 9.34616 23.5125 9.25016 22.3735 9.05816Z"
      fill="#CEEAF5"
    />
  </svg>
);
