import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import * as Sentry from '@sentry/react';
import './index.css';
import { redirectToProdUrlForAliasUrls } from '@/lib/env';

if (window.location.protocol === 'https:') {
  Sentry.init({
    dsn: 'https://6f4308f19314d9a55899000406ad8009@o4507933954342912.ingest.us.sentry.io/4507934048452608',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // 'localhost',
      'rres-app.rres-develop.rres.io',
      'app.rres.io',
      'www.leadsiege.io',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

redirectToProdUrlForAliasUrls();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
