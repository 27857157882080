import { z } from 'zod';
import {
  AEStageEnum,
  BoardType,
  LOStageEnum,
  SalesPipeline,
  StageEnum,
} from '@/pages/crm/lead-stages/types';
import {
  CommonProperty,
  ManualProperty,
  MortgageType,
  Property,
} from '@/pages/admin/property-data/property-table';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { capitalize, isBlank } from '@/utils';

const SortOrderSchema = z.enum(['oldest', 'newest']);
export type SortOrder = z.infer<typeof SortOrderSchema>;

export const boardSortStateSchema = z.object({
  assigned_lead: SortOrderSchema,
  follow_up: SortOrderSchema,
  front_line_processing: SortOrderSchema,
  refinancing: SortOrderSchema,
  refinanced: SortOrderSchema,
  lost: SortOrderSchema,
  potential_lead: SortOrderSchema,
  first_call: SortOrderSchema,
  second_call: SortOrderSchema,
  third_call: SortOrderSchema,
  interested_party: SortOrderSchema,
  ae_lost: SortOrderSchema,
});

export type BoardSortState = z.infer<typeof boardSortStateSchema>;

export const defaultBordSortState: BoardSortState = {
  assigned_lead: 'oldest',
  follow_up: 'oldest',
  front_line_processing: 'oldest',
  refinancing: 'oldest',
  refinanced: 'oldest',
  lost: 'oldest',
  potential_lead: 'oldest',
  first_call: 'oldest',
  second_call: 'oldest',
  third_call: 'oldest',
  interested_party: 'oldest',
  ae_lost: 'oldest',
};

export const loStages: StageEnum[] = [
  'assigned_lead',
  'follow_up',
  'front_line_processing',
  'refinancing',
  'refinanced',
  'lost',
];

export const aeStages: StageEnum[] = [
  'potential_lead',
  'first_call',
  'second_call',
  'third_call',
  'interested_party',
  'ae_lost',
];

export const allStages: StageEnum[] = [...loStages, ...aeStages];

const moveableStages: Record<StageEnum, StageEnum[]> = {
  assigned_lead: ['follow_up', 'lost'],
  follow_up: ['assigned_lead', 'front_line_processing', 'lost'],
  front_line_processing: ['follow_up', 'refinancing', 'lost'],
  refinancing: ['front_line_processing', 'refinanced', 'lost'],
  refinanced: ['refinancing'],
  lost: [
    'assigned_lead',
    'follow_up',
    'front_line_processing',
    'refinancing',
    'refinanced',
  ],
  potential_lead: ['first_call', 'interested_party', 'ae_lost'],
  first_call: ['potential_lead', 'second_call', 'interested_party', 'ae_lost'],
  second_call: ['first_call', 'third_call', 'interested_party', 'ae_lost'],
  third_call: ['second_call', 'interested_party', 'ae_lost'],
  interested_party: ['third_call'],
  ae_lost: [
    'potential_lead',
    'first_call',
    'second_call',
    'third_call',
    'interested_party',
  ],
};

export const canCardMove = (source: StageEnum, destination: StageEnum) => {
  return moveableStages[source].includes(destination);
};

export const isCardDisabled = (
  boardType: BoardType,
  loStage: LOStageEnum,
  aeStageEnum?: AEStageEnum | null
) => {
  if (boardType === 'LO') {
    return (
      loStage === 'follow_up' &&
      aeStageEnum &&
      !['ae_lost', 'interested_party'].includes(aeStageEnum)
    );
  } else {
    return aeStageEnum === 'interested_party';
  }
};

export const isPromoteStage = (source: StageEnum, destination: StageEnum) => {
  return (
    (source === 'assigned_lead' && destination === 'follow_up') ||
    (source === 'follow_up' && destination === 'front_line_processing') ||
    (source === 'front_line_processing' && destination === 'refinancing') ||
    (source === 'refinancing' && destination === 'refinanced') ||
    (source === 'potential_lead' && destination === 'first_call') ||
    (source === 'first_call' && destination === 'second_call') ||
    (source === 'second_call' && destination === 'third_call') ||
    (source === 'third_call' && destination === 'interested_party')
  );
};

export const isPassingToAE = (
  salesPipeline: SalesPipeline,
  destination: StageEnum
) => {
  return (
    !salesPipeline.aeStage &&
    salesPipeline.stage === 'assigned_lead' &&
    destination === 'follow_up'
  );
};

export const isPassingToLO = (destination: StageEnum) => {
  return destination === 'interested_party';
};

export const isDemoteStage = (source: StageEnum, destination: StageEnum) => {
  return (
    (source === 'follow_up' && destination === 'assigned_lead') ||
    (source === 'front_line_processing' && destination === 'follow_up') ||
    (source === 'refinancing' && destination === 'front_line_processing') ||
    (source === 'refinanced' && destination === 'refinancing') ||
    (source === 'first_call' && destination === 'potential_lead') ||
    (source === 'second_call' && destination === 'first_call') ||
    (source === 'third_call' && destination === 'second_call') ||
    (source === 'interested_party' && destination === 'third_call')
  );
};

export const isReactivate = (source: StageEnum, destination: StageEnum) => {
  return (
    (source === 'lost' &&
      [
        'assigned_lead',
        'follow_up',
        'front_line_processing',
        'refinancing',
        'refinanced',
      ].includes(destination)) ||
    (source === 'ae_lost' &&
      [
        'potential_lead',
        'first_call',
        'second_call',
        'third_call',
        'interested_party',
      ].includes(destination))
  );
};

export const isClose = (destination: StageEnum) => {
  return destination === 'lost' || destination === 'ae_lost';
};

export const getMortgageType = (
  salesPipeline?: SalesPipeline
): MortgageType | undefined => {
  const commonProperty =
    salesPipeline?.property ??
    (salesPipeline?.manualProperty as CommonProperty);
  return (MORTGAGE_TYPE_MAP[commonProperty?.originalMortgageType ?? ''] ??
    commonProperty?.originalMortgageType) as MortgageType;
};

export const getOwnerEmails = (
  salesPipeline?: SalesPipeline
): (string | null | undefined)[] => {
  if (!salesPipeline) return [];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (email: string | null | undefined): boolean => {
    return email ? emailRegex.test(email) : false;
  };

  const emails = new Set<string>();

  if (salesPipeline.manualProperty) {
    const firstOwnerEmail = salesPipeline.manualProperty.firstCurrentOwnerEmail;
    const secondOwnerEmail =
      salesPipeline.manualProperty.secondCurrentOwnerEmail;
    if (validateEmail(firstOwnerEmail)) emails.add(firstOwnerEmail as string);
    if (validateEmail(secondOwnerEmail)) emails.add(secondOwnerEmail as string);
  }

  if (salesPipeline.property) {
    const firstOwnerEmail = salesPipeline.property.firstCurrentOwnerEmail;
    const secondOwnerEmail = salesPipeline.property.secondCurrentOwnerEmail;
    if (validateEmail(firstOwnerEmail)) emails.add(firstOwnerEmail as string);
    if (validateEmail(secondOwnerEmail)) emails.add(secondOwnerEmail as string);
  }

  return Array.from(emails);
};

export const getOwnerEmail = (
  salesPipeline?: SalesPipeline
): string | null | undefined => {
  const emails = getOwnerEmails(salesPipeline);
  return emails.length > 0 ? emails[0] : null;
};

export const sortSalesPipelines = (
  salesPipelines: SalesPipeline[],
  sortOrder: SortOrder
): SalesPipeline[] => {
  return [...salesPipelines].sort((a, b) => {
    const aLastActivity = a.lastActivityAt
      ? new Date(a.lastActivityAt).getTime()
      : null;
    const bLastActivity = b.lastActivityAt
      ? new Date(b.lastActivityAt).getTime()
      : null;

    if (aLastActivity === null && bLastActivity === null) {
      // Both lastActivityAt are null, sort by createdAt
      if (sortOrder === 'oldest') {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    }

    if (aLastActivity === null) return sortOrder === 'oldest' ? -1 : 1; // a is null, put it at the top if sorting by oldest
    if (bLastActivity === null) return sortOrder === 'oldest' ? 1 : -1; // b is null, put it at the top if sorting by oldest

    // Both lastActivityAt are not null, sort by lastActivityAt
    if (sortOrder === 'oldest') {
      return aLastActivity - bLastActivity;
    } else {
      return bLastActivity - aLastActivity;
    }
  });
};

export const getFullAddressFromProperty = (
  property?: Property,
  manualProperty?: ManualProperty
): string => {
  if (manualProperty && !isBlank(manualProperty?.situsFullAddress)) {
    return capitalize(manualProperty?.situsFullAddress);
  }

  if (property) {
    return `${capitalize(property.situsStreetAddress)}, ${capitalize(property.situsCity)}, ${property.state}, ${property.zipCode}`;
  }

  return `N/A`;
};
