import React, { createContext, useCallback, useContext, useState } from 'react';
import { MapFilterState } from '@/pages/map/map/types';
import { MarketFilterState } from '@/pages/market/market/market-container';
import { useQuery } from '@tanstack/react-query';
import { User } from '@/pages';
import { camelizeKeys, snakeizeKeys } from '@/utils';
import axios from 'axios';
import { TimezoneType } from '@/utils/timezones';

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  isSuperuser: boolean;
  userType: 'LO' | 'AE';
  latestMapFilter?: Partial<MapFilterState>;
  latestMarketFilter?: Partial<MarketFilterState>;
  lastAssignedToAeUserId?: number;
  lastAssignedToLoUserId?: number | null;
  lastAssignedLeadUserId?: number | null;
  lastCrmActivityFilterOnDays?: number | null;
  timezone: TimezoneType;
  cardPassUserId?: number | null;
}

interface UserAuthProps extends UserDetails {
  token: string;
}

type ProfileForm = Partial<
  Omit<UserAuthProps, 'isSuperuser' | 'userType' | 'token'>
>;

interface AuthContextProps {
  isAuthenticated: boolean;
  userDetails: UserAuthProps;
  login: (userAuthProps: UserAuthProps) => void;
  logout: () => void;
  refresh: () => void;
  updateUserDetails: (userAuthProps: UserAuthProps) => void;
  updateProfile: (form: ProfileForm) => Promise<UserDetails>;
}

export const USER_AUTH_KEY = 'userAuth';
const getUserAuth = (): UserAuthProps | undefined => {
  const userAuthString = localStorage.getItem(USER_AUTH_KEY);
  if (!userAuthString?.includes('firstName')) {
    return undefined;
  }

  return JSON.parse(userAuthString) as UserAuthProps;
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userAuth = getUserAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(
    userAuth !== undefined
  );
  const [userDetails, setUserDetails] = useState<UserAuthProps | undefined>(
    userAuth
  );

  const { refetch: fetchProfile } = useQuery<User>({
    queryKey: ['me', userDetails?.token],
    queryFn: async () => {
      const { data } = await axios.get('/api/me', {
        headers: { Authorization: `Bearer ${userDetails?.token}` },
      });

      return camelizeKeys(data);
    },
    enabled: false,
  });

  const updateUserDetails = useCallback((userAuthProps: UserAuthProps) => {
    localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuthProps));
    setUserDetails(userAuthProps);
  }, []);

  const updateProfile = useCallback(
    async (form: ProfileForm) => {
      if (userDetails) {
        updateUserDetails({
          ...userDetails,
          ...form,
        });
      }

      const { data } = await axios.patch('/api/me', snakeizeKeys(form), {
        headers: { Authorization: `Bearer ${userDetails?.token}` },
      });

      return camelizeKeys(data);
    },
    [updateUserDetails, userDetails]
  );

  const login = useCallback(
    (userAuthProps: UserAuthProps) => {
      setIsAuthenticated(true);
      updateUserDetails(userAuthProps);
    },
    [updateUserDetails]
  );

  const logout = useCallback(() => {
    localStorage.removeItem(USER_AUTH_KEY);
    setIsAuthenticated(false);
    setUserDetails(undefined);
    window.location.href = '/login';
  }, []);

  const refresh = useCallback(async () => {
    const { data } = await fetchProfile();
    if (data) {
      setUserDetails((prevState) => ({
        token: prevState?.token ?? '',
        ...data,
      }));
    }
  }, [fetchProfile]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        refresh,
        updateUserDetails,
        updateProfile,
        userDetails: userDetails ?? {
          firstName: '',
          lastName: '',
          email: '',
          isSuperuser: false,
          token: '',
          userType: 'LO',
          latestMapFilter: {},
          latestMarketFilter: {},
          timezone: 'PT',
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
