import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'text-label-md flex items-center space-x-1.5 items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'text-label-md button-md bg-navy-navy text-light-tint hover:bg-orange-shade hover:text-navy-navy active:bg-navy-shade active:text-light-tint',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'button-md bg-light-tint text-navy-navy hover:bg-orange-shade hover:text-navy-navy active:bg-navy-shade active:text-light-tint',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        red: 'text-red-shade bg-red-tint hover:text-red-tint hover:bg-red-red active:text-red-tint active:bg-red-red',
        blue: 'text-blue-shade bg-blue-tint hover:text-blue-tint hover:bg-blue-blue active:text-blue-tint active:bg-blue-shade',
        yellow:
          'text-yellow-shade bg-yellow-tint hover:text-yellow-tint hover:bg-yellow-yellow active:text-yellow-tint active:bg-yellow-shade',
        green:
          'text-green-shade bg-green-tint hover:text-green-tint hover:bg-green-green active:text-green-tint active:bg-green-shade',
        orange:
          'text-navy-navy bg-orange-shade hover:text-navy-navy hover:bg-orange-orange active:text-orange-tint active:bg-orange-shade',
      },
      size: {
        default: 'h-10 px-5 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  activeClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
