export const VoicemailIcon = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.60938 8C8.60938 8.46413 8.425 8.90925 8.09681 9.23744C7.76862 9.56563 7.3235 9.75 6.85937 9.75C6.39525 9.75 5.95013 9.56563 5.62194 9.23744C5.29375 8.90925 5.10938 8.46413 5.10938 8C5.10938 7.53587 5.29375 7.09075 5.62194 6.76256C5.95013 6.43437 6.39525 6.25 6.85937 6.25C7.3235 6.25 7.76862 6.43437 8.09681 6.76256C8.425 7.09075 8.60938 7.53587 8.60938 8ZM14.8594 6.25C14.3952 6.25 13.9501 6.43437 13.6219 6.76256C13.2937 7.09075 13.1094 7.53587 13.1094 8C13.1094 8.46413 13.2937 8.90925 13.6219 9.23744C13.9501 9.56563 14.3952 9.75 14.8594 9.75C15.3235 9.75 15.7686 9.56563 16.0968 9.23744C16.425 8.90925 16.6094 8.46413 16.6094 8C16.6094 7.53587 16.425 7.09075 16.0968 6.76256C15.7686 6.43437 15.3235 6.25 14.8594 6.25ZM2.98438 0C2.42079 0 1.88029 0.223883 1.48177 0.622398C1.08326 1.02091 0.859375 1.56142 0.859375 2.125V13.875C0.859375 14.4386 1.08326 14.9791 1.48177 15.3776C1.88029 15.7761 2.42079 16 2.98438 16H18.7344C19.298 16 19.8385 15.7761 20.237 15.3776C20.6355 14.9791 20.8594 14.4386 20.8594 13.875V2.125C20.8594 1.56142 20.6355 1.02091 20.237 0.622398C19.8385 0.223883 19.298 0 18.7344 0H2.98438ZM9.29638 6.25C9.68904 6.79675 9.88642 7.45966 9.85676 8.13215C9.82711 8.80463 9.57216 9.4476 9.1329 9.95767C8.69364 10.4677 8.0956 10.8152 7.43496 10.9443C6.77431 11.0734 6.08945 10.9766 5.4905 10.6693C4.89156 10.3621 4.41334 9.8624 4.13274 9.25054C3.85215 8.63867 3.78549 7.95021 3.94348 7.29588C4.10147 6.64154 4.47493 6.05936 5.00381 5.64294C5.53268 5.22652 6.18624 5.00007 6.85937 5H14.8594C15.5325 5.00007 16.1861 5.22652 16.7149 5.64294C17.2438 6.05936 17.6173 6.64154 17.7753 7.29588C17.9333 7.95021 17.8666 8.63867 17.586 9.25054C17.3054 9.8624 16.8272 10.3621 16.2282 10.6693C15.6293 10.9766 14.9444 11.0734 14.2838 10.9443C13.6231 10.8152 13.0251 10.4677 12.5859 9.95767C12.1466 9.4476 11.8916 8.80463 11.862 8.13215C11.8323 7.45966 12.0297 6.79675 12.4224 6.25H9.29638Z"
      fill="#192A68"
    />
  </svg>
);
