import { useCallback, useEffect, useState } from 'react';
import { Calendar } from '@/components/ui/calendar';
import { TimeDropdown } from '@/components/map/property-details/time-dropdown';
import { Button } from '@/components/ui/button';
import { createLocalDatetime, formatDateVerbose } from '@/utils/date';
import {
  getLocalDatetime,
  getTimezoneString,
  TIMEZONES,
  TimezoneType,
} from '@/utils/timezones';

interface DueDateSettingProps {
  dueDatetime: string;
  setDueDatetime(datetime: string, timezone: TimezoneType): void;
  onCancel(): void;
  timezone: TimezoneType;
}

interface FormState {
  timezone: TimezoneType;
  date: string;
  time: string;
}

export const DueDateSetting = ({
  dueDatetime,
  setDueDatetime,
  onCancel,
  timezone,
}: DueDateSettingProps) => {
  const [timezoneDatetime, setTimezoneDatetime] = useState<FormState>({
    date: '',
    time: '',
    timezone: 'PT',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setTimezoneDatetime(getLocalDatetime(dueDatetime, timezone));
  }, [dueDatetime, timezone]);

  const handleDateChange = useCallback((newDate: Date | undefined) => {
    if (!newDate) return;

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setTimezoneDatetime((prevState) => ({
      ...prevState,
      date: formattedDate,
    }));
  }, []);

  const handleTimeChange = useCallback((time: string) => {
    setTimezoneDatetime((prevState) => ({
      ...prevState,
      time,
    }));
  }, []);

  const handleApply = useCallback(() => {
    const currentDateTime = new Date();
    const localDatetimeString = getTimezoneString({
      date: timezoneDatetime.date,
      time: timezoneDatetime.time,
      timezone: timezoneDatetime.timezone,
    });

    const localDatetime = new Date(localDatetimeString);

    if (localDatetime && localDatetime < currentDateTime) {
      setErrorMessage('Due date cannot be in the past.');
      return false;
    }
    setDueDatetime(localDatetimeString, timezoneDatetime.timezone);
    return true;
  }, [
    timezoneDatetime.date,
    timezoneDatetime.time,
    timezoneDatetime.timezone,
    setDueDatetime,
  ]);

  const handleCancel = useCallback(() => {
    setErrorMessage(null);
    setTimezoneDatetime(getLocalDatetime(dueDatetime, timezone));
    onCancel();
  }, [dueDatetime, onCancel, timezone]);

  return (
    <div className="flex flex-col space-y-4">
      <Calendar
        mode="single"
        selected={createLocalDatetime(timezoneDatetime.date)}
        onSelect={handleDateChange}
        initialFocus
      />

      <div className="flex justify-between items-center px-3">
        <div>
          {formatDateVerbose(createLocalDatetime(timezoneDatetime.date))}
        </div>
        <div className="flex items-center space-x-0.5">
          <TimeDropdown
            selectedTime={timezoneDatetime.time}
            onChange={handleTimeChange}
          />
          <div>
            <select
              value={timezoneDatetime.timezone}
              onChange={(e) => {
                setTimezoneDatetime((prev) => ({
                  ...prev,
                  timezone: e.target.value as TimezoneType,
                }));
              }}
            >
              {Object.keys(TIMEZONES)
                .sort()
                .map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      {errorMessage && <div className="text-red-500 px-3">{errorMessage}</div>}

      <div className="flex justify-end items-center space-x-3 p-3">
        <Button variant="ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (handleApply()) {
              handleCancel();
            }
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
