import { MiniStats } from '@/components';
import { ContactIcon, CupIcon, ShakeHandIcon } from '@/components/icons';
import { DollarIcon } from '@/pages/crm/team/team/dollar-icon';
import { LeaderboardStatItem } from '@/pages/crm/team/team/types';
import { formatShortestMoney } from '@/utils';
import { calculateConversionRate } from '@/pages/crm/team/team/utils';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface LeaderboardItemProps {
  order: number;
  data: LeaderboardStatItem;
  boardType: BoardType;
}

export const LeaderboardItem = ({
  order,
  data,
  boardType,
}: LeaderboardItemProps) => {
  const monthlyConversionRate = calculateConversionRate(
    data.month.refinanced,
    data.month.followUp
  );

  const quarterlyConversionRate = calculateConversionRate(
    data.quarter.refinanced,
    data.quarter.followUp
  );

  const yearlyConversionRate = calculateConversionRate(
    data.year.refinanced,
    data.year.followUp
  );

  const secondStageName =
    boardType === 'LO' ? 'Follow-up' : 'First Calls Make to Leads';
  const fifthStageName =
    boardType === 'LO' ? 'Refinanced Leads' : 'Interested Parties';
  const lastStageName =
    boardType === 'LO' ? 'Refinanced' : 'Interested Parties';

  return (
    <div className="w-full px-4 py-2 bg-light-tint rounded-lg shadow">
      <div className="flex items-center space-x-3 mb-2 mt-2">
        <div className="text-heading-02 text-navy-navy w-10">{order}</div>
        <div>
          <img
            src="/assets/images/avatar.png"
            alt="Avatar Icon"
            className="w-8 h-8"
          />
        </div>
        <div className="text-heading-04 text-navy-navy">{data.userName}</div>
      </div>
      <div className="p-3 grid grid-cols-1 lg:grid-cols-2 gap-6 mx-5">
        <div className="space-y-3 bg-light-light rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="text-heading-05 text-navy-navy">
              {secondStageName}
            </div>
            <div>
              <ContactIcon className="h-9 w-9 text-navy-navy" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="orange-tint"
              value={data.month.followUp}
              label="Monthly"
              small
            />
            <MiniStats
              variant="orange"
              value={data.quarter.followUp}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="orange-shade"
              value={data.year.followUp}
              label="Yearly"
              small
            />
          </div>
        </div>
        <div className="space-y-3 bg-light-light rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="text-heading-05 text-navy-navy">
              {fifthStageName}
            </div>
            <div>
              <CupIcon className="h-9 w-9 text-blue-tint fill-blue-shade" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="blue-tint"
              value={data.month.refinanced.toLocaleString()}
              label="Monthly"
              small
            />
            <MiniStats
              variant="blue"
              value={data.quarter.refinanced.toLocaleString()}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="blue-shade"
              value={data.year.refinanced.toLocaleString()}
              label="Yearly"
              small
            />
          </div>
        </div>
        <div className="space-y-3 bg-light-light rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="text-heading-05 text-navy-navy">
              Conversion Rate
            </div>
            <div>
              <ShakeHandIcon className="h-9 w-9 text-navy-tint" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="navy-tint"
              value={monthlyConversionRate}
              label="Monthly"
              small
            />
            <MiniStats
              variant="navy"
              value={quarterlyConversionRate}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="navy-shade"
              value={yearlyConversionRate}
              label="Yearly"
              small
            />
          </div>
        </div>

        <div className="space-y-3 bg-light-light rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="text-heading-05 text-navy-navy">
              {lastStageName} Mtg. Bals. Sum
            </div>
            <div>
              <DollarIcon className="h-9 w-9 text-green-tint" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="green-tint"
              value={formatShortestMoney(data.month.refinancedAmount)}
              label="Monthly"
              small
            />
            <MiniStats
              variant="green"
              value={formatShortestMoney(data.quarter.refinancedAmount)}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="green-shade"
              value={formatShortestMoney(data.year.refinancedAmount)}
              label="Yearly"
              small
            />
          </div>
        </div>
      </div>
    </div>
  );
};
