import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { User } from '@/pages';
import { useAuth } from '@/context';
import { BoardType } from '@/pages/crm/lead-stages/types';
import { useMemo } from 'react';

interface UserDropdownProps {
  selectedUserId?: number;
  users: User[];
  onUserChange: (userId: number) => void;
  filterOutCurrentUser?: boolean;
  alwaysIncludeCurrentUser?: boolean;
  borderType?: BoardType;
  extraOptions?: { label: string; value: number }[];
  extraEndOptions?: { label: string; value: number }[];
  selectLabel?: string;
}

export const UserDropdown = ({
  selectedUserId,
  users,
  filterOutCurrentUser,
  onUserChange,
  borderType,
  alwaysIncludeCurrentUser,
  extraOptions,
  selectLabel,
  extraEndOptions,
}: UserDropdownProps) => {
  const { userDetails } = useAuth();

  let filteredUsers = users.filter((u) => u.isActive);
  if (filterOutCurrentUser) {
    filteredUsers = filteredUsers.filter((u) => u.email === userDetails.email);
  }

  if (borderType) {
    filteredUsers = filteredUsers.filter((u) => {
      if (alwaysIncludeCurrentUser && u.email === userDetails.email) {
        return true;
      }

      return u.userType === borderType;
    });
  }

  const label = useMemo(() => {
    if (!selectedUserId) {
      return selectLabel ?? 'Select User';
    }

    const user = users.find((u) => u.id === selectedUserId);
    if (user) {
      return `${user.firstName} ${user.lastName} - ${user.isSuperuser ? 'Admin' : user.userType}`;
    }

    return (
      [...(extraOptions ?? []), ...(extraEndOptions ?? [])].find(
        (o) => o.value === selectedUserId
      )?.label ?? 'Unknown'
    );
  }, [extraEndOptions, extraOptions, selectLabel, selectedUserId, users]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="blue">
          <span>{label}</span>
          <span>
            <DropdownButtonIcon />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade max-h-80 overflow-y-auto">
        <DropdownMenuGroup>
          {extraOptions?.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.value}
              className="focus:text-blue-tint focus:bg-blue-blue"
              onClick={() => {
                if (option.value !== selectedUserId) {
                  onUserChange(option.value);
                }
              }}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
          {filteredUsers.map((user) => (
            <DropdownMenuCheckboxItem
              checked={user.id === selectedUserId}
              key={user.id}
              className="focus:text-blue-tint focus:bg-blue-blue"
              onClick={() => {
                if (user.id !== selectedUserId) {
                  onUserChange(user.id);
                }
              }}
            >
              {user.firstName} {user.lastName}
              {' - '}
              {user.isSuperuser ? 'Admin' : user.userType}
            </DropdownMenuCheckboxItem>
          ))}
          {extraEndOptions?.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.value}
              className="focus:text-blue-tint focus:bg-blue-blue"
              onClick={() => {
                if (option.value !== selectedUserId) {
                  onUserChange(option.value);
                }
              }}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
