import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useApi } from '@/hooks/use-api';
import { useQuery } from '@tanstack/react-query';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { UserPerformanceTable } from '@/pages/admin/user-performance/user-performance-table';
import { UserPerformanceListResponse } from '@/pages/admin/user-performance/types';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { useState } from 'react';
import { Loader } from '@/components';
import { Sidebar } from '@/pages/settings/common/sidebar';

type UseType = '' | 'admin' | 'normal_user';

const UserFilterOptions = [
  { label: 'All User Type', value: '' },
  { label: 'Admin', value: 'admin' },
  { label: 'Loan Officer', value: 'normal_user' },
];

const DateFilterOptions = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 365 days', value: 365 },
];

export const UserPerformance = () => {
  const { getRequest } = useApi();

  const [state, setState] = useState<{ userType: UseType; days: number }>({
    userType: '',
    days: 7,
  });

  const { isPending, error, data } = useQuery<UserPerformanceListResponse>({
    queryKey: ['userPerformances', state.days],
    queryFn: () => getRequest(`/api/users/performances?days=${state.days}`),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  const users =
    data?.data?.filter((user) => {
      if (state.userType === 'admin') {
        return user.isSuperuser;
      }

      if (state.userType === 'normal_user') {
        return !user.isSuperuser;
      }

      return true;
    }) || [];

  return (
    <Sidebar currentPage="user-performance">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">User Performance</div>
        </div>
        <Card>
          <CardHeader className="flex flex-row items-center">
            <CardTitle className="flex justify-between items-center w-full">
              <div></div>
              <div className="text-sm font-normal flex space-x-3">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="blue">
                      <span>
                        {
                          DateFilterOptions.find(
                            ({ value }) => value === state.days
                          )?.label
                        }
                      </span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
                    <DropdownMenuGroup>
                      {DateFilterOptions.map((option) => (
                        <DropdownMenuCheckboxItem
                          checked={state.days === option.value}
                          key={option.value}
                          className="focus:text-blue-tint focus:bg-blue-blue"
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              days: option.value,
                            }))
                          }
                        >
                          {option.label}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="blue">
                      <span>
                        {
                          UserFilterOptions.find(
                            ({ value }) => value === state.userType
                          )?.label
                        }
                      </span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
                    <DropdownMenuGroup>
                      {UserFilterOptions.map((option) => (
                        <DropdownMenuCheckboxItem
                          checked={state.userType === option.value}
                          key={option.value}
                          className="focus:text-blue-tint focus:bg-blue-blue"
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              userType: option.value as UseType,
                            }))
                          }
                        >
                          {option.label}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isPending && <Loader />}
            {data && <UserPerformanceTable users={users} />}
          </CardContent>
        </Card>
      </div>
    </Sidebar>
  );
};
