import { moneyFormatter } from './formaters';

export const formatShortMoney = (amount: number): string => {
  const formattedMoney = moneyFormatter.format(amount);
  if (formattedMoney.endsWith(',000,000')) {
    return formattedMoney.replace(/,000,000$/, 'M');
  } else if (formattedMoney.endsWith(',000')) {
    return formattedMoney.replace(/,000$/, 'k');
  }
  return formattedMoney;
};

export const formatShortestMoney = (num: number) => {
  const formatter = new Intl.NumberFormat('en-US');
  if (num >= 1000000) {
    const millions = num / 1000000;
    if (millions >= 100) {
      return formatter.format(Math.floor(millions)) + 'M';
    }
    return (
      (millions % 1 === 0 || (millions >= 100 && millions < 1000)
        ? formatter.format(Math.floor(millions))
        : formatter.format(Number(millions.toFixed(1)))) + 'M'
    );
  } else if (num >= 1000) {
    const thousands = num / 1000;
    return (
      (thousands % 1 === 0 || (thousands >= 100 && thousands < 1000)
        ? formatter.format(Math.floor(thousands))
        : formatter.format(Number(thousands.toFixed(1)))) + 'K'
    );
  }
  return num.toString();
};

export const formatRoundedMoney = (amount: number): string => {
  if (amount < 1000000) {
    const roundedAmount = Math.round(amount / 1000) * 1000;
    return `$${parseFloat((roundedAmount / 1000).toFixed(1))}k`;
  } else {
    const roundedAmount = Math.round(amount / 10000) * 10000;
    return `$${parseFloat((roundedAmount / 1000000).toFixed(2))}M`;
  }
};
