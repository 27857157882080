import { MortgageType } from '@/pages/admin/property-data/property-table';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';

interface Option {
  value: MortgageType;
  label: string;
}

export const OPTIONS: Option[] = [
  {
    value: 'Conventional',
    label: 'CNV',
  },
  {
    value: 'Federal Housing Administration',
    label: 'FHA',
  },
  {
    value: 'Veterans Affairs',
    label: 'VA',
  },
];

export interface MortgageTypeFilterProps {
  selectedMortgageTypes: MortgageType[];
  onSelectMortgageTypes: (selectedMortgageTypes: MortgageType[]) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const MortgageTypeFilter = ({
  selectedMortgageTypes,
  onSelectMortgageTypes,
  defaultClose,
  onChange,
}: MortgageTypeFilterProps) => {
  const handleMortgageTypeChange = (mortgageType: MortgageType) => {
    if (selectedMortgageTypes.includes(mortgageType)) {
      const newMortgageTypes = selectedMortgageTypes.filter(
        (item) => item !== mortgageType
      );
      if (newMortgageTypes.length > 0) {
        onSelectMortgageTypes(newMortgageTypes);
      }
    } else {
      onSelectMortgageTypes([...selectedMortgageTypes, mortgageType]);
    }
  };

  return (
    <FilterContainer
      value="mortgage-type"
      title={
        <div className="text-heading-05 text-dark-dark">Mortgage Structure</div>
      }
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {OPTIONS.map(({ value, label }) => (
          <CheckboxWithLabel
            key={value}
            label={label}
            checked={selectedMortgageTypes.includes(value)}
            onClick={() => handleMortgageTypeChange(value)}
            normalCase
          />
        ))}
      </div>
    </FilterContainer>
  );
};
