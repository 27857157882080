import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/context/user-context';
import { Link } from '@/components/ui/link';
import { Menu, Package2 } from 'lucide-react';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { NavLink } from './nav-link';
import { LogoIcon, AvatarIcon, LogoutIcon } from '@/components/icons';
import { useEventTracking } from '@/hooks/use-event-tracking';
import { Notification } from '@/components/layouts/top-navigation/notification';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';

const NavigatorDropdownMenuItem = ({ ...props }) => (
  <DropdownMenuItem
    className="h-12 text-button-lg font-[14px] text-white hover:text-dark-dark focus:transition-colors focus:bg-orange-shade justify-center uppercase focus:rounded-none"
    {...props}
  />
);

export const TopNavigation = () => {
  const { logout, userDetails } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const userType = userDetails?.userType ?? 'LO';
  useEventTracking();

  return (
    <header className="top-navbar">
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link
          href="/"
          className="flex items-center text-lg font-semibold md:text-base md:w-[248px]"
        >
          <LogoIcon className="flex-1 -mt-2.5" />
          <span className="sr-only">LeadSiege</span>
        </Link>
        <div className="flex gap-[1px] bg-white border-l border-r border-white">
          <NavLink href="/map" pathname={pathname}>
            Map
          </NavLink>
          {userDetails?.isSuperuser ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={`h-[60px] flex items-center px-8 uppercase whitespace-nowrap text-button-lg cursor-pointer ${
                    pathname.startsWith('/lead-stages')
                      ? 'text-dark-dark transition-colors bg-orange-shade'
                      : 'text-white transition-colors bg-dark-dark hover:text-dark-dark hover:bg-orange-shade data-[state=open]:text-dark-dark data-[state=open]:bg-orange-shade'
                  }`}
                >
                  CRM
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="center" className="bg-dark-dark border-0 rounded-none w-[102px]">
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/lead-stages/lo');
                  }}
                >
                  LO CRM
                </NavigatorDropdownMenuItem>
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/lead-stages/ae');
                  }}
                >
                  AE CRM
                </NavigatorDropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <NavLink
              href={`/lead-stages/${userType.toLocaleLowerCase()}`}
              pathname={pathname}
            >
              {userType} CRM
            </NavLink>
          )}

          {(userType === 'LO' || userDetails.isSuperuser) && (
            <NavLink href="/market" pathname={pathname}>
              Market
            </NavLink>
          )}
          {userDetails?.isSuperuser ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={`h-[60px] flex items-center px-8 uppercase whitespace-nowrap text-button-lg cursor-pointer ${
                    pathname === `/sales-funnel/${userType.toLocaleLowerCase()}`
                      ? 'text-dark-dark transition-colors bg-orange-shade'
                      : 'text-white transition-colors bg-dark-dark hover:text-dark-dark hover:bg-orange-shade data-[state=open]:text-dark-dark data-[state=open]:bg-orange-shade'
                  }`}
                >
                  Funnel
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="center" className="bg-dark-dark border-0 rounded-none">
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/sales-funnel/lo');
                  }}
                >
                  LO Funnel
                </NavigatorDropdownMenuItem>
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/sales-funnel/ae');
                  }}
                >
                  AE Funnel
                </NavigatorDropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <NavLink href="/sales-funnel" pathname={pathname}>
              {userType} Funnel
            </NavLink>
          )}

          {userDetails?.isSuperuser ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={`h-[60px] flex items-center px-8 uppercase whitespace-nowrap text-button-lg cursor-pointer ${
                    pathname === `/team/${userType.toLocaleLowerCase()}`
                      ? 'text-dark-dark transition-colors bg-orange-shade'
                      : 'text-white transition-colors bg-dark-dark hover:text-dark-dark hover:bg-orange-shade data-[state=open]:text-dark-dark data-[state=open]:bg-orange-shade'
                  }`}
                >
                  Team
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="center" className="bg-dark-dark border-0 rounded-none">
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/team/lo');
                  }}
                >
                  LO Team
                </NavigatorDropdownMenuItem>
                <NavigatorDropdownMenuItem
                  onClick={() => {
                    navigate('/team/ae');
                  }}
                >
                  AE Team
                </NavigatorDropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <NavLink href="/team" pathname={pathname}>
              {userType} Team
            </NavLink>
          )}

          {userDetails.isSuperuser && (
            <NavLink href="/admin/users" pathname={pathname}>
              Admin
            </NavLink>
          )}
        </div>
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              href="/"
              className="flex items-center gap-2 text-lg font-semibold"
            >
              <Package2 className="h-6 w-6" />
              <span className="sr-only">Lead Siege</span>
            </Link>
            <NavLink href="/map" pathname={pathname}>
              Map
            </NavLink>
            <NavLink href="/lead-stages" pathname={pathname}>
              {userType} CRM
            </NavLink>
            {(userType === 'LO' || userDetails.isSuperuser) && (
              <NavLink href="/market" pathname={pathname}>
                Market
              </NavLink>
            )}
            <NavLink href="/sales-funnel" pathname={pathname}>
              {userType} Funnel
            </NavLink>
            <NavLink href="/team" pathname={pathname}>
              {userType} Team
            </NavLink>
            {userDetails?.isSuperuser && (
              <NavLink href="/admin/users" pathname={pathname}>
                Admin
              </NavLink>
            )}
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form className="ml-auto flex-1 sm:flex-initial"></form>
        <Notification />
        <button
          className="text-dark-dark"
          onClick={() => {
            navigate('/settings/profile');
          }}
        >
          <AvatarIcon
            active={
              pathname.includes('/settings') || pathname.includes('/admin')
            }
          />
          <span className="sr-only">Toggle user menu</span>
        </button>
        <button
          onClick={() => {
            logout();
            navigate('/login');
          }}
        >
          <LogoutIcon />
          <span className="sr-only">Logout</span>
        </button>
      </div>
    </header>
  );
};
