import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { ManualProperty } from '@/pages/admin/property-data/property-table';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { MAPBOX_ACCESS_TOKEN } from '@/utils/config';
import { AddressAutofill } from '@mapbox/search-js-react';
import { formatInteger } from '@/utils/number';
import { USPhoneInput } from '@/components/ui/us-phone-input';

interface NewLeadDialogProps {
  open: boolean;

  onClose?(): void;

  createNewLead?(newLead: ManualProperty): Promise<void>;

  isCreating?: boolean;
}

const nameRegex = /^[a-zA-Z\s]*$/;
const phoneRegex = /^\([2-9]\d{2}\) [2-9]\d{2}-\d{4}$/;

const formSchema = z.object({
  firstCurrentOwnerFirstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .max(30, { message: 'First name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'First name must contain only letters and spaces',
    }),
  firstCurrentOwnerLastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .max(30, { message: 'Last name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'Last name must contain only letters and spaces',
    }),
  firstCurrentOwnerPhoneNumber: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  firstCurrentOwnerPhoneNumber2: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  firstCurrentOwnerEmail: z
    .string()
    .email({ message: 'Invalid email format' })
    .max(50, { message: 'Email must be 50 characters or less' })
    .optional(),
  secondCurrentOwnerFirstName: z
    .string()
    .max(30, { message: 'First name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'First name must contain only letters and spaces',
    })
    .optional(),
  secondCurrentOwnerLastName: z
    .string()
    .max(30, { message: 'Last name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'Last name must contain only letters and spaces',
    })
    .optional(),
  secondCurrentOwnerPhoneNumber: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  secondCurrentOwnerPhoneNumber2: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  secondCurrentOwnerEmail: z
    .string()
    .email({ message: 'Invalid email format' })
    .max(50, { message: 'Email must be 50 characters or less' })
    .optional(),
  situsFullAddress: z
    .string()
    .min(1, { message: 'Address is required' })
    .max(200, { message: 'Address must be 200 characters or less' }),
  originalMortgageInterestRate: z
    .string()
    .max(10, { message: 'Interest rate must be 10 characters or less' })
    .optional(),
  originalMortgageAmount: z.string().optional(),
  originalMortgageType: z
    .enum([
      'Conventional',
      'Federal Housing Administration',
      'Veterans Affairs',
    ])
    .optional(),
  originalMortgageLender: z
    .string()
    .max(200, { message: 'Lender must be 200 characters or less' })
    .optional(),
});

export const NewLeadDialog = ({
  open,
  onClose,
  createNewLead,
  isCreating,
}: NewLeadDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstCurrentOwnerFirstName: '',
    },
  });

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [form, open]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await createNewLead?.(values);
  };

  return (
    <div>
      {open && (
        <div
          data-state="open"
          className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
          style={{ pointerEvents: 'auto' }}
          data-aria-hidden="true"
          aria-hidden="true"
        ></div>
      )}
      <Dialog open={open} onOpenChange={() => onClose?.()} modal={false}>
        <DialogContent
          className="sm:max-w-[800px]"
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>New Lead</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form
              className="w-full space-y-5"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <h2 className="text-label-md text-navy-navy">First Owner*</h2>
              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="firstCurrentOwnerFirstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          autoFocus
                          placeholder="FIRST NAME"
                          maxLength={30}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="firstCurrentOwnerLastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="LAST NAME"
                          maxLength={30}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="firstCurrentOwnerPhoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <USPhoneInput
                          {...field}
                          placeholder="(___) ___-____ FIRST PHONE NUMBER"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="firstCurrentOwnerPhoneNumber2"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <USPhoneInput
                          {...field}
                          placeholder="(___) ___-____ SECOND PHONE NUMBER"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="space-y-1">
                  <FormField
                    control={form.control}
                    name="firstCurrentOwnerEmail"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="PERSON@EXAMPLE.COM EMAIL"
                            maxLength={50}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <h2 className="text-label-md text-navy-navy pt-6">
                Second Owner
              </h2>
              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="secondCurrentOwnerFirstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="FIRST NAME"
                          maxLength={30}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="secondCurrentOwnerLastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="LAST NAME"
                          maxLength={30}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="secondCurrentOwnerPhoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <USPhoneInput
                          {...field}
                          placeholder="(___) ___-____ FIRST PHONE NUMBER"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="secondCurrentOwnerPhoneNumber2"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <USPhoneInput
                          {...field}
                          placeholder="(___) ___-____ SECOND PHONE NUMBER"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="space-y-1">
                  <FormField
                    control={form.control}
                    name="secondCurrentOwnerEmail"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="PERSON@EXAMPLE.COM EMAIL"
                            maxLength={50}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mt-6 space-y-3">
                <div className="text-label-md text-navy-navy">
                  Property Details*
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <FormField
                    control={form.control}
                    name="situsFullAddress"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-expect-error */}
                            <AddressAutofill
                              options={{ country: 'us' }}
                              accessToken={MAPBOX_ACCESS_TOKEN}
                              onRetrieve={(result) => {
                                const properties =
                                  result.features[0].properties;
                                setTimeout(() => {
                                  field.onChange(properties.place_name);
                                }, 1);
                              }}
                            >
                              <Input
                                {...field}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                placeholder="ADDRESS"
                                maxLength={200}
                              />
                            </AddressAutofill>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="originalMortgageLender"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="LENDER"
                            maxLength={200}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <FormField
                    control={form.control}
                    name="originalMortgageType"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Select
                            value={field.value ?? undefined}
                            onValueChange={(value) => field.onChange(value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select a mortgage type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {Object.keys(MORTGAGE_TYPE_MAP).map((key) => (
                                  <SelectItem key={key} value={key}>
                                    {MORTGAGE_TYPE_MAP[key]}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="originalMortgageAmount"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                              $
                            </span>
                            <Input
                              {...field}
                              placeholder="EST MORTGAGE AMOUNT"
                              className="pl-7"
                              onKeyDown={(e) => {
                                if (e.key === '.') {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                const value = e.currentTarget.value.replace(
                                  /,/g,
                                  ''
                                );
                                if (/^\d*$/.test(value)) {
                                  if (value.length > 9) {
                                    e.currentTarget.value = value.slice(0, 9);
                                  } else {
                                    e.currentTarget.value = value;
                                  }
                                } else {
                                  e.currentTarget.value = value.replace(
                                    /\D/g,
                                    ''
                                  );
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(
                                  e.currentTarget.value.replace(/,/g, '')
                                );
                              }}
                              value={
                                field.value
                                  ? formatInteger(parseInt(field.value))
                                  : ''
                              }
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="originalMortgageInterestRate"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="relative">
                            <Input
                              {...field}
                              placeholder="INTEREST RATE"
                              maxLength={6}
                              type="number"
                              min={0.5}
                              max={20}
                              step={0.125}
                              className="pr-7"
                              onInput={(e) => {
                                const value = parseFloat(e.currentTarget.value);
                                if (value > 20) {
                                  e.currentTarget.value = '20';
                                } else if (value < 0.5) {
                                  e.currentTarget.value = '0.5';
                                } else if (
                                  e.currentTarget.value.includes('.') &&
                                  e.currentTarget.value.split('.')[1].length > 3
                                ) {
                                  e.currentTarget.value = value.toFixed(3);
                                }
                              }}
                            />
                            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">
                              %
                            </span>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button type="submit" disabled={isCreating}>
                  {isCreating ? 'Creating' : 'Create'}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
