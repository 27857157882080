import { IMaskInput } from 'react-imask';
import { InputHTMLAttributes } from 'react';
import { isBlank } from '@/utils';

type USPhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange?: (value: string) => void;
  value?: string;
};

const DEFAULT_PLACEHOLDER = '(___) ___-____';

export const USPhoneInput = ({
  value,
  onChange,
  ...props
}: USPhoneInputProps) => {
  const handleAccept = (formattedValue: string) => {
    if (formattedValue !== DEFAULT_PLACEHOLDER) {
      onChange?.(formattedValue);
    }
  };

  return (
    <IMaskInput
      mask="(000) 000-0000"
      value={value}
      onAccept={handleAccept}
      placeholder={DEFAULT_PLACEHOLDER}
      className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      lazy={isBlank(value)}
      {...props}
    />
  );
};
