import { format } from 'date-fns';
import { getDatetimeFormattedString } from '@/utils/timezones';

export const toISODate = (date: Date | undefined | null): string =>
  date ? date.toISOString().split('T')[0] : '';

export const formatDate = (date: Date | string | undefined | null): string =>
  date ? format(date, 'MM/dd/y') : '';

export const formatDateVerbose = (
  date: Date | string | undefined | null
): string => (date ? format(new Date(date), 'dd MMM yyyy') : '');

export const formatDatetimeVerbose = (
  datetime: Date | string | undefined | null
): string => {
  if (!datetime) return '';

  const date = new Date(datetime);
  const formattedDate = formatDateVerbose(date);
  const formattedTime = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return `${formattedDate} at ${formattedTime}`;
};

export const getPtDatetimeFormattedString = (
  isoDateTimeString: string
): string => {
  return getDatetimeFormattedString(isoDateTimeString, 'PT');
};

export const createLocalDatetime = (
  dateString: string,
  timeString: string = '00:00:00'
): Date | undefined => {
  if (!dateString) return undefined;

  try {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const date = new Date(year, month - 1, day);
    date.setHours(hours, minutes, seconds, 0);
    return date;
  } catch {
    return new Date();
  }
};

export const areDatetimeEqual = (
  datetime1?: string,
  datetime2?: string
): boolean => {
  if (datetime1 === undefined && datetime2 === undefined) {
    return true;
  }

  if (!datetime1 || !datetime2) {
    return false;
  }

  const date1 = new Date(datetime1);
  const date2 = new Date(datetime2);

  return date1.getTime() === date2.getTime();
};
