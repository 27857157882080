/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { camelizeKeys, snakeizeKeys } from '@/utils/obj';
import { useAuth, USER_AUTH_KEY } from '@/context';
import { useCallback } from 'react';
import { isLocal } from '@/utils/config';

export type EventName =
  | 'user_login'
  | 'view_map'
  | 'view_market'
  | 'view_lead_stages'
  | 'view_sales_funnel'
  | 'view_team'
  | 'view_ae_lead_stages'
  | 'view_ae_sales_funnel'
  | 'view_ae_team'
  | 'view_admin'
  | 'view_property_card'
  | 'edit_notes'
  | 'edit_task';

const getApiUrl = (pathname: string) => {
  if (window.location.host.includes('localhost')) {
    return `http://localhost:8000${pathname}`;
  }

  return pathname;
};

export const useApi = () => {
  const { userDetails } = useAuth();
  const token = userDetails?.token;
  const navigate = useNavigate();

  const api = axios.create({
    baseURL: getApiUrl(''),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleError = useCallback(
    (error: any) => {
      if (error instanceof AxiosError) {
        const status = error.response?.status;
        if (status === 401 || status === 403) {
          localStorage.removeItem(USER_AUTH_KEY);
          navigate('/login');
        }

        if (error?.response?.data.detail) {
          throw Error(error.response.data.detail);
        }
      }
      throw error;
    },
    [navigate]
  );

  const getRequest = useCallback(
    async (pathname: string) => {
      try {
        const response = await api.get(pathname, {
          maxRedirects: 2,
        });
        return camelizeKeys(response.data);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError]
  );

  const postRequest = useCallback(
    async (pathname: string, newEntity: any) => {
      try {
        const response = await api.post(pathname, snakeizeKeys(newEntity));
        return camelizeKeys(response.data);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError]
  );

  const patchRequest = useCallback(
    async (pathname: string, newEntity: any) => {
      try {
        const response = await api.patch(pathname, snakeizeKeys(newEntity));
        return camelizeKeys(response.data);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError]
  );

  const putRequest = useCallback(
    async (pathname: string, newEntity: any) => {
      try {
        const response = await api.put(pathname, snakeizeKeys(newEntity));
        return camelizeKeys(response.data);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError]
  );

  const deleteRequest = useCallback(
    async (pathname: string) => {
      try {
        const response = await api.delete(pathname);
        return camelizeKeys(response.data);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError]
  );

  const sendEvent = useCallback(
    async (eventName: EventName, eventData = {}) => {
      if (isLocal) {
        console.log('Event:', eventName, eventData);
      }
      await postRequest('/api/events', { eventName, eventData });
    },
    [postRequest]
  );

  return {
    getRequest,
    postRequest,
    patchRequest,
    putRequest,
    deleteRequest,
    sendEvent,
  };
};
