import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { Owners } from '@/components/map/property-details/owners';
import { GeneralDetails } from '@/components/map/property-details/general-details';
import { NotesTaskDetails } from '@/components/map/property-details/notes-task-details';
import {
  OutboundCall,
  OwnerForm,
} from '@/components/map/property-details/types';
import { TriStateToggle } from '@/pages/crm/lead-stages/tri-state-toggle/tri-state-toggle';
import { Label } from '@/components/ui/label';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';

interface MainSectionProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
}

export const MainSection = ({
  mapProperty,
  property,
  salesPipeline,
  updateSalesPipelineOwner,
  updateSalesPipeline,
}: MainSectionProps) => {
  const { getRequest } = useApi();
  const { data: outboundCalls, refetch } = useQuery<OutboundCall[]>({
    queryKey: ['outboundCalls', salesPipeline?.id],
    queryFn: () =>
      getRequest(`/api/sales_pipelines/${salesPipeline?.id}/outbound_calls`),
  });

  return (
    <div className="flex space-x-8">
      <div className="w-[350px]">
        <Owners
          property={property}
          salesPipeline={salesPipeline}
          updateSalesPipelineOwner={updateSalesPipelineOwner}
          updateSalesPipeline={updateSalesPipeline}
          refetchOutboundCalls={refetch}
        />
      </div>

      <div>
        <GeneralDetails
          mapProperty={mapProperty}
          property={salesPipeline?.property ?? property}
          salesPipeline={salesPipeline}
        />
      </div>

      {salesPipeline && (
        <div className="w-[360px]">
          <NotesTaskDetails
            salesPipeline={salesPipeline}
            updateSalesPipeline={updateSalesPipeline}
            outboundCalls={outboundCalls}
          />
        </div>
      )}

      <div className="space-y-5 w-[180px]">
        <div className="space-y-1">
          <Label className="font-normal normal-case">Temporary Buydown</Label>
          <TriStateToggle
            value={salesPipeline?.isTemporaryBuydown ?? null}
            onChange={(value) =>
              updateSalesPipeline?.(salesPipeline!.id, {
                isTemporaryBuydown: value,
              })
            }
          />
        </div>
        {(salesPipeline?.property?.originalMortgageType ??
          salesPipeline?.manualProperty?.originalMortgageType) ===
          'Veterans Affairs' && (
          <div className="space-y-1">
            <Label className="font-normal normal-case">Disability Rating</Label>
            <TriStateToggle
              value={salesPipeline?.isDisabilityRating ?? null}
              onChange={(value) =>
                updateSalesPipeline?.(salesPipeline!.id, {
                  isDisabilityRating: value,
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
