import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AllMortgageTypes,
  formSchema,
  ManualDataImportFormData,
} from '@/pages/data-pipeline/manual-data-import/types';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { BalanceSlider } from '@/components/filters/sections/balance-slider';
import { InterestRateSlider } from '@/components/filters/sections/interest-rate-slider';
import { Button } from '@/components/ui/button';
import { DownloadIcon } from '@/pages/settings/common/icons/download-icon';
import { useManualDataImport } from '@/pages/data-pipeline/hooks/use-manual-data-import';
import { useCallback, useEffect, useState } from 'react';
import { formatInteger } from '@/utils/number';
import { EyeIcon } from '@/pages/data-pipeline/icons/eye-icon';
import { StateSelector } from '@/components/filters/sections/state-selector';
import { TwoFieldsDateRangeSelector } from '@/components/filters/sections/two-fields-date-range-selector';
import { isProd } from '@/lib/env';
import { loadPreference, storePreference } from '@/utils/preference';

const MaxAllowImportCount = isProd() ? 10_000 : 50;

const defaultManualImportState: ManualDataImportFormData = {
  states: ['HI'],
  transactionType: 1,
  sellerCarryback: 0,
  mortgageType: ['FHA'],
  mortgageAmount: {
    from: 200_000,
    to: 900_000,
  },
  interestRate: {
    from: 7,
    to: 13,
  },
  lastSaleDate: {
    from: new Date('2022-10-01'),
    to: new Date('2024-07-01'),
  },
  ownershipType: 1,
};

export const ManualDataImportContainer = () => {
  const { task, getCount, clearCount, importData } = useManualDataImport();

  const form = useForm<ManualDataImportFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: loadPreference(
      'lastManualImportState',
      formSchema,
      defaultManualImportState
    ),
  });

  const [isImporting, setIsImporting] = useState(false);

  const watchedValues = useWatch({
    control: form.control,
  });

  useEffect(() => {
    clearCount();
  }, [clearCount, watchedValues]);

  const onSubmit: SubmitHandler<ManualDataImportFormData> = useCallback(
    async (values) => {
      setIsImporting(true);
      storePreference('lastManualImportState', formSchema, {
        ...values,
        count: undefined,
      });
      if (
        task.status === 'SUCCESS' &&
        task.result?.count &&
        task.result.count <= MaxAllowImportCount
      ) {
        await importData({ ...values, count: task.result.count });
      } else {
        await getCount(values);
      }
      setIsImporting(false);
    },
    [getCount, importData, task.result?.count, task.status]
  );

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-start items-stretch w-full bg-white py-10">
            <div className="max-w-4xl gap-5 grid grid-cols-2">
              <FormField
                control={form.control}
                name="mortgageType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mortgage type</FormLabel>
                    <FormControl>
                      <div className="space-x-3">
                        {AllMortgageTypes.map((type) => (
                          <button
                            key={type}
                            onClick={(e) => {
                              e.preventDefault();
                              field.onChange(
                                field.value.includes(type)
                                  ? field.value.filter((t) => t !== type)
                                  : [...field.value, type]
                              );
                            }}
                          >
                            <Badge
                              variant="blue"
                              className={
                                field.value.includes(type)
                                  ? 'bg-blue-shade text-blue-tint'
                                  : ''
                              }
                            >
                              {type}
                            </Badge>
                          </button>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastSaleDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last sale date</FormLabel>
                    <FormControl>
                      <div className="-mx-6">
                        <TwoFieldsDateRangeSelector
                          selectedDateRange={{
                            from: new Date(field.value.from),
                            to: new Date(field.value.to),
                          }}
                          onSelectDateRange={(dateRange) => {
                            field.onChange({
                              from: dateRange.from,
                              to: dateRange.to,
                            });
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="transactionType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Transaction Type</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value?.toString()}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select transaction type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="1">
                            Last Market Sale: Arms-Length
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="sellerCarryback"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Seller carrybacks</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={(value) =>
                          field.onChange(parseInt(value))
                        }
                        value={field.value?.toString()}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select seller carrybacks" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="0">
                            No Seller Carrybacks
                          </SelectItem>
                          <SelectItem value="1">
                            Allow Seller Carrybacks
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="mortgageAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mortgage Amount</FormLabel>
                    <FormControl>
                      <div className="-mx-6">
                        <BalanceSlider
                          selectedBalanceRange={field.value}
                          onSelectBalanceRange={field.onChange}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="interestRate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Interest rate</FormLabel>
                    <FormControl>
                      <div className="-mx-6">
                        <InterestRateSlider
                          selectedInterestRateRange={field.value}
                          onSelectInterestRateRange={field.onChange}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="states"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>States</FormLabel>
                    <FormControl>
                      <div className="-mx-6">
                        <StateSelector
                          selectedStates={field.value}
                          onSelectStates={(selectedCounties: string[]) =>
                            field.onChange(selectedCounties)
                          }
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="ownershipType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ownership type</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={(value) =>
                          field.onChange(parseInt(value))
                        }
                        value={field.value?.toString()}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select ownership type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectContent>
                            <SelectItem value="0">
                              Exclude Corporate Owned
                            </SelectItem>
                            <SelectItem value="1">
                              Only Corporate Owned
                            </SelectItem>
                            <SelectItem value="2">
                              Include All Ownership Types
                            </SelectItem>
                          </SelectContent>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="bg-light-light w-[400px] h-full p-6 rouded-md mx-6 flex justify-center">
              {task.status === 'SUCCESS' && task.result ? (
                <div className="mt-24 space-y-3 w-full flex flex-col items-center">
                  {task.result?.count ? (
                    <div className="space-y-2">
                      <div className="text-display-01 text-center">
                        {formatInteger(task.result.count)}
                      </div>
                      <div className="text-heading-04">Matching Properties</div>
                      <div>
                        <Button
                          className="disabled:cursor-not-allowed flex space-x-2"
                          disabled={
                            task.result?.count > MaxAllowImportCount ||
                            isImporting
                          }
                          type="submit"
                        >
                          <div>
                            <DownloadIcon colorClassNames="" />
                          </div>
                          <div>Import property data</div>
                        </Button>
                      </div>
                      {task.result?.count > MaxAllowImportCount && (
                        <div className="text-yellow-yellow">
                          Individual manual data import size limited to {formatInteger(MaxAllowImportCount)} properties.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-paragraph-md text-red-red">
                      Error getting count: {task.result?.error}
                    </div>
                  )}
                </div>
              ) : (
                <Button
                  className="disabled:cursor-not-allowed flex space-x-2"
                  type="submit"
                  disabled={task.status === 'PENDING' || isImporting}
                >
                  <div>
                    <EyeIcon colorClassNames="" />
                  </div>
                  <div>
                    {task.status === 'PENDING'
                      ? 'Getting Count...'
                      : 'Get Count'}
                  </div>
                </Button>
              )}
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
