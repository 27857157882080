import { FilterContainer } from '@/components/filters/sections/filter-container';
import { TeamStats } from '@/pages/crm/team/team/team-stats';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/context';
import { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import {
  Loader,
  TeamLossReasonChart,
  TeamLossReasonByMonthChart,
  TeamLossReasonByBrokerChart,
  ConversionRateChart,
  TeamRefinancedChart,
  SalesFunnelChart,
} from '@/components';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { TeamStatsResponse } from '@/pages/crm/team/team/types';
import { LeaderboardItem } from '@/pages/crm/team/team/leaderboard-item';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface TeamContainerProps {
  boardType: BoardType;
}

export const TeamContainer = ({ boardType }: TeamContainerProps) => {
  const lastStageName =
    boardType === 'LO' ? 'Refinanced' : 'Interested Parties';

  const { getRequest } = useApi();
  const { userDetails } = useAuth();
  const [includeCurrentMonth, setIncludeCurrentMonth] = useState(
    userDetails?.isSuperuser
  );
  const { error, data } = useQuery<TeamStatsResponse>({
    queryKey: ['team-stats', boardType, includeCurrentMonth],
    queryFn: () =>
      getRequest(
        `/api/stats/team_stats?board_type=${boardType.toLowerCase()}${includeCurrentMonth ? '&include_current_month=true' : ''}`
      ),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <div
      className="flex w-full space-x-5"
      style={{ height: 'calc(100vh - 108px)' }}
    >
      <div className="w-full h-full overflow-y-auto bg-gradient-to-b from-[#e5e7ee] to-[#e5e7ee] rounded-lg p-3 space-y-6">
        <div className="flex justify-between items-end mt-3">
          <div className="text-heading-01"></div>
          <div>
            <CheckboxWithLabel
              label="Include current month"
              checked={includeCurrentMonth}
              onClick={() => setIncludeCurrentMonth(!includeCurrentMonth)}
            />
          </div>
        </div>
        <FilterContainer
          value="stats"
          itemClassName="bg-light-light rounded-lg shadow py-4"
          triggerClassName="bg-light-light rounded-lg"
          title={
            <div className="text-heading-01 text-navy-navy">Team Stats</div>
          }
        >
          <TeamStats
            data={data?.chartData ?? []}
            salesFunnel={data.salesFunnel}
            quarterSalesFunnel={data.quarterSalesFunnel}
            boardType={boardType}
          />
        </FilterContainer>

        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
          <div className="col-span-3">
            <FilterContainer
              value="stats"
              itemClassName="bg-light-light rounded-lg shadow py-4"
              triggerClassName="bg-light-light rounded-lg"
              title={
                <div className="text-left space-y-1">
                  <div className="text-heading-01 text-navy-navy">
                    Lead Loss Reason Over Time
                  </div>
                  <div className="text-heading-04 text-navy-navy">
                    Team Aggregate
                  </div>
                </div>
              }
            >
              <div className="m-6">
                <TeamLossReasonByMonthChart
                  data={data?.lostReasonStatsByMonth ?? []}
                />
              </div>
            </FilterContainer>
          </div>

          <div className="col-span-2">
            <FilterContainer
              value="stats"
              itemClassName="bg-light-light rounded-lg shadow py-4"
              triggerClassName="bg-light-light rounded-lg"
              title={
                <div className="text-left space-y-1">
                  <div className="text-heading-01 text-navy-navy">
                    {boardType} Team Funnel
                  </div>
                </div>
              }
            >
              <div className="m-6">
                <div className="w-full flex items-center justify-center mt-16 h-[360px]">
                  <SalesFunnelChart
                    data={data?.salesFunnel}
                    boardType={boardType}
                  />
                </div>
              </div>
            </FilterContainer>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <FilterContainer
            value="stats"
            itemClassName="bg-light-light rounded-lg shadow py-4"
            triggerClassName="bg-light-light rounded-lg"
            title={
              <div className="text-left space-y-1">
                <div className="text-heading-01 text-navy-navy">
                  Lead Loss Reason
                </div>
                <div className="text-heading-04 text-navy-navy">
                  Team Aggregate
                </div>
              </div>
            }
          >
            <div className="m-6">
              <TeamLossReasonChart data={data?.lostReasonStatsByMonth ?? []} />
            </div>
          </FilterContainer>

          <FilterContainer
            value="stats"
            itemClassName="bg-light-light rounded-lg shadow py-4"
            triggerClassName="bg-light-light rounded-lg"
            title={
              <div className="text-left space-y-1">
                <div className="text-heading-01 text-navy-navy">
                  Lead Loss Reason
                </div>
                <div className="text-heading-04 text-navy-navy">
                  By {boardType}
                </div>
              </div>
            }
          >
            <div className="m-6">
              <TeamLossReasonByBrokerChart
                data={data?.lostReasonStatsByUser ?? []}
              />
            </div>
          </FilterContainer>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <FilterContainer
            value="stats"
            itemClassName="bg-light-light rounded-lg shadow py-4"
            triggerClassName="bg-light-light rounded-lg"
            title={
              <div className="text-left space-y-1">
                <div className="text-heading-01 text-navy-navy">
                  {lastStageName} Mtg. Balances
                </div>
                <div className="text-heading-04 text-navy-navy">
                  Team aggregate sum
                </div>
              </div>
            }
          >
            <div className="m-6">
              <TeamRefinancedChart
                data={data?.chartData ?? []}
                boardType={boardType}
              />
            </div>
          </FilterContainer>

          <FilterContainer
            value="stats"
            itemClassName="bg-light-light rounded-lg shadow py-4"
            triggerClassName="bg-light-light rounded-lg"
            title={
              <div className="text-left space-y-1">
                <div className="text-heading-01 text-navy-navy">
                  Conversion rate
                </div>
                <div className="text-heading-04 text-navy-navy">
                  Team aggregate
                </div>
              </div>
            }
          >
            <div className="m-6">
              <ConversionRateChart
                data={data?.chartData ?? []}
                boardType={boardType}
              />
            </div>
          </FilterContainer>
        </div>

        <hr className="border-navy-navy my-12" />

        <div className="grid gap-4 grid-cols-1">
          <div>
            <FilterContainer
              value="aggregate-sum-of-refinanced-mortgage-balance"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  Leaderboard
                </div>
              }
            >
              <div className="mt-3 space-y-3">
                {data?.leaderboardStats?.map((leaderboardItem, index) => (
                  <LeaderboardItem
                    key={index}
                    order={index + 1}
                    data={leaderboardItem}
                    boardType={boardType}
                  />
                ))}
              </div>
            </FilterContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
