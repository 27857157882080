const PRODUCTION_URL = 'www.leadsiege.io';

export const isProd = () => {
  const prodHostnames = [
    'app.rres.io',
    'rres-app.rres-prod.rres.io',
    PRODUCTION_URL,
  ];
  return prodHostnames.includes(window.location.hostname);
};

export const redirectToProdUrlForAliasUrls = () => {
  if (isProd() && window.location.hostname !== PRODUCTION_URL) {
    window.location.href = `https://${PRODUCTION_URL}`;
  }
};
