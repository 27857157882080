import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { EventName, useApi } from '@/hooks/use-api';

const EventMap: Record<string, EventName> = {
  '/map': 'view_map',
  '/market': 'view_market',
  '/lead-stages/lo': 'view_lead_stages',
  '/sales-funnel/lo': 'view_sales_funnel',
  '/team/lo': 'view_team',
  '/lead-stages/ae': 'view_ae_lead_stages',
  '/sales-funnel/ae': 'view_ae_sales_funnel',
  '/team/ae': 'view_ae_team',
  '/admin/users': 'view_admin',
};

export const useEventTracking = () => {
  const location = useLocation();
  const { sendEvent } = useApi();
  const { pathname } = location;
  const lastLogTimeRef = useRef<number>(0);

  useEffect(() => {
    const now = Date.now();
    if (now - lastLogTimeRef.current > 100) {
      const eventName = EventMap[pathname];
      if (eventName) {
        lastLogTimeRef.current = now;
        (async () => await sendEvent(eventName))();
      }
    }
  }, [pathname, sendEvent]);
};
