import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SortingState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  PaginationState,
} from '@tanstack/react-table';
import { AlertCircle, Trash2Icon } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useEffect, useState } from 'react';
import { Loader } from '@/components';
import { useApi } from '@/hooks/use-api';
import { isLocal } from '@/utils/config';
import { AllStageEnum } from '@/components/filters/sections/lead-stage-filter';
import { StageEnum } from '@/pages/crm/lead-stages/types';
import { DataTableExport } from '@/pages/admin/property-data/data-table-export';
import { DataTableViewOptions } from '@/pages/admin/property-data/data-table-view-options';
import { createColumns } from '@/pages/admin/property-data/columns';
import { ReactTable } from '@/components/table/react-table';
import { SearchIcon } from '@/pages/crm/lead-stages/icons/search-icon';
import { Button } from '@/components/ui/button';
import { searchProperties } from '@/pages/admin/property-data/utils';
import { useToast } from '@/components/ui/use-toast';
import { loadPreference, storePreference } from '@/utils/preference';
import { z } from 'zod';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';
import { TimezoneType } from '@/utils/timezones';

export type LeaderCompetitiveness = 0 | 1 | 2 | 3 | 4 | -1;
export type CallableOwners = 0 | 1 | 2;
export type EmailableOwners = 0 | 1 | 2;
export type MortgageType =
  | 'Conventional'
  | 'Federal Housing Administration'
  | 'Veterans Affairs';

export type DncStatus = 'DNC' | 'OPEN';

export interface MapProperty {
  id: number;
  quartile: number;
  state: string;
  lenderCompetitivenessRanking: LeaderCompetitiveness;
  originalMortgageType: MortgageType;
  longitude: number;
  latitude: number;
  callableOwners: CallableOwners;
  emailableOwners: EmailableOwners;
  originalMortgageInterestRate: number | string | null;
  originalMortgageAmount: number | string | null;
  recordDateLastArmsLengthSale: string | null;
  yearBuilt: number | null;
  doNotMail: boolean;
  salesPipelineId?: number;
}

export interface MapPropertyWithStage extends MapProperty {
  stage: AllStageEnum;
  aeStage?: AllStageEnum;
  loStage: AllStageEnum;
  loAssignedUserId?: number;
  aeAssignedUserId?: number;
}

export interface AtomData {
  id: number;
  attomIdentifier: number;
}

export interface CommonProperty {
  firstCurrentOwnerFirstName?: string | null;
  firstCurrentOwnerLastName?: string | null;
  firstCurrentOwnerPhoneNumber?: string | null;
  firstCurrentOwnerPhoneNumber2?: string | null;
  firstCurrentOwnerEmail?: string | null;
  secondCurrentOwnerFirstName?: string | null;
  secondCurrentOwnerLastName?: string | null;
  secondCurrentOwnerPhoneNumber?: string | null;
  secondCurrentOwnerPhoneNumber2?: string | null;
  secondCurrentOwnerEmail?: string | null;
  situsFullAddress?: string | null;
  originalMortgageInterestRate?: number | string | null;
  originalMortgageAmount?: number | string | null;
  originalMortgageType?: MortgageType | null;
  originalMortgageLender?: string | null;
  timezone?: TimezoneType | null;
}

export interface ManualProperty extends CommonProperty {}

export interface Property extends MapProperty {
  apn: string;
  fips: number;
  county: string;
  originalMortgageLender: string;
  situsFullAddress: string;
  propertySalePrice: string;
  firstCurrentOwnerFirstName: string | null;
  firstCurrentOwnerLastName: string | null;
  firstCurrentOwnerPhoneNumber?: string | null;
  firstCurrentOwnerPhoneNumber2?: string | null;
  firstCurrentOwnerEmail: string | null;
  firstCurrentOwnerPhoneIsConnected: boolean;
  firstOwnerDncConnectionStatus: string | null;
  firstCurrentOwnerPhoneType: string;
  firstOwnerDncIsCell: boolean | null;
  firstOwnerDncDncStatus: null | DncStatus;
  firstOwnerDncDncStatus2: null | DncStatus;
  secondCurrentOwnerFirstName: string | null;
  secondCurrentOwnerLastName: string | null;
  secondCurrentOwnerPhoneNumber?: string | null;
  secondCurrentOwnerPhoneNumber2?: string | null;
  secondCurrentOwnerEmail: string | null;
  secondCurrentOwnerPhoneIsConnected: boolean;
  secondOwnerDncConnectionStatus: string | null;
  secondCurrentOwnerPhoneType: string;
  secondOwnerDncIsCell: boolean | null;
  secondOwnerDncDncStatus: null | DncStatus;
  secondOwnerDncDncStatus2: null | DncStatus;
  bedrooms: number | null;
  bathrooms: number | null;
  homeSqft: number | null;
  atomData: AtomData | null;
  situsStreetAddress?: string | null;
  situsCity?: string | null;
  zipCode?: number | null;
  estimatedMortgageBalance: number | null;
  estimatedMonthlyMortgagePayment: number | null;
  timezone: TimezoneType | null;
}

export interface PropertyExtension extends Property {
  notes?: string;
}

export interface MapPropertyListResponse {
  count: number;
  data: MapProperty[];
}

export interface PropertyListResponse {
  count: number;
  data: Property[];
}

export interface SimpleSalesPipeline {
  id: number;
  stage: StageEnum;
  loStage: StageEnum;
  aeStage?: StageEnum;
  notes?: string;
  lostReasonDescription?: string;
  loAssignedUserId?: number;
  aeAssignedUserId?: number;
}

interface PropertyTableProps {
  dataImportId?: number;
}

const columnVisibilitySchema = z.record(z.boolean());

export const PropertyTable = ({ dataImportId }: PropertyTableProps) => {
  const { getRequest, postRequest } = useApi();
  const { toast } = useToast();
  const [search, setSearch] = useState<string>('');
  const { isPending, error, data } = useQuery<PropertyListResponse>({
    queryKey: ['propertiesDataAll', dataImportId],
    queryFn: () =>
      getRequest(
        `/api/properties?limit=${isLocal ? 200 : 100000}${dataImportId ? `&data_import_id=${dataImportId}` : ''}`
      ),
  });
  const {
    isPending: isAppSettingLoading,
    data: appSettingData,
    error: appSettingError,
  } = useQuery<AppSettingResponse>({
    queryKey: ['app-setting'],
    queryFn: () => getRequest('/api/app/setting'),
  });
  const { data: salesPipelines } = useQuery<
    Record<number, SimpleSalesPipeline>
  >({
    queryKey: ['salesPipelineDataAll'],
    queryFn: () => getRequest('/api/sales_pipelines/all'),
  });

  const [properties, setProperties] = useState<PropertyExtension[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    loadPreference(
      'propertyTableColumnVisibilityState',
      columnVisibilitySchema,
      {}
    )
  );
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [deletedRowIds, setDeletedRowIds] = useState<number[]>([]);

  useEffect(() => {
    storePreference(
      'propertyTableColumnVisibilityState',
      columnVisibilitySchema,
      columnVisibility
    );
  }, [columnVisibility]);

  useEffect(() => {
    setSelectedRowIds([]);
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (data && salesPipelines && appSettingData) {
      setProperties(
        searchProperties(
          search,
          data.data.map((property) => {
            property.firstCurrentOwnerEmail;
            const salesPipeline = salesPipelines[property.id];
            return {
              ...property,
              ...(salesPipeline ?? {}),
            };
          })
        ).filter((property) => !deletedRowIds.includes(property.id))
      );
    }
  }, [data, deletedRowIds, salesPipelines, search, appSettingData]);

  const viewOnly = dataImportId !== undefined;

  const { isPending: isDeleting, mutateAsync: deleteProperty } = useMutation({
    mutationFn: () =>
      postRequest('/api/properties/delete_properties', {
        propertyIds: selectedRowIds,
      }),
    onSuccess: () => {
      setDeletedRowIds([...deletedRowIds, ...selectedRowIds]);
      // filter out selected rows
      toast({
        variant: 'success',
        description: `${selectedRowIds.length} properties deleted`,
        title: 'Property Deleted',
      });

      setSelectedRowIds([]);
    },
  });

  const table = useReactTable<Property>({
    data: properties,
    columns: createColumns(
      selectedRowIds,
      setSelectedRowIds,
      viewOnly,
      appSettingData
    ),
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error || appSettingError) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          {error?.message || appSettingError?.message}
        </AlertDescription>
      </Alert>
    );
  }

  if (isPending || isAppSettingLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-4 mt-6">
      <div className="flex justify-between items-center w-full py-2 rounded-lg">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            id="simple-search"
            autoComplete="off"
            className="h-8 w-[420px] text-label-md bg-gray-50 border border-gray-300 rounded ps-9 p-1 normal-case"
            placeholder="SEARCH BY OWNER, ADDRESS, PHONE..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-2">
          {!viewOnly && (
            <Button
              className="flex space-x-2"
              onClick={async () => {
                await deleteProperty();
              }}
              disabled={isDeleting || selectedRowIds.length === 0}
            >
              <span>
                <Trash2Icon className="h-4 w-4" />
              </span>
            </Button>
          )}
          <DataTableExport table={table} />
          <DataTableViewOptions table={table} />
        </div>
      </div>
      {selectedRowIds.length > 0 && (
        <div>{selectedRowIds.length} row(s) selected</div>
      )}
      <div>
        {isPending && <Loader />}
        {properties && <ReactTable table={table} showSource />}
      </div>
    </div>
  );
};
