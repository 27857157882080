import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import { Button } from '@/components/ui/button';
import { StageDetails } from '@/components/map/property-details/stage-details';
import {
  BoardType,
  SalesPipeline,
  StageEnum,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { TickButtonIcon } from '@/components/map/property-details/icons/tick-button-icon';
import { useCallback, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { AllLostReasons } from '@/components/crm/types';
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { ButtonGroup } from '@/components/map/property-details/button-group';
import {
  aeStages,
  isCardDisabled,
  isPassingToAE,
  isPassingToLO,
  loStages,
} from '@/components/crm/board/utils';
import {
  AssignCardConfirmDialog,
  AssignCardConfirmDialogProps,
} from '@/components/crm/board/assign-card-confirm-dialog';
import { User } from '@/pages';
import { useAuth } from '@/context';

interface TopSectionProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;
  users: User[];
  boardType?: BoardType;
  onOpenChange?(open: boolean): void;

  changeStage?(
    salesPipelineId: number,
    fromStage: string,
    toStage: string,
    passingToUserId?: number
  ): Promise<void>;

  closeAsLost?(
    salesPipelineId: number,
    fromStage: string,
    lostReasonCode: number,
    lostReasonDescription: string
  ): Promise<void>;

  reactivate?(salesPipelineId: number): Promise<void>;

  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
}

export const getNextStage = (
  currentStage: StageEnum,
  boardType: BoardType
): StageEnum | null => {
  const stages = boardType === 'LO' ? loStages : aeStages;
  const currentIndex = stages.indexOf(currentStage);
  if (currentIndex === -1 || currentIndex === stages.length - 1) {
    return null;
  }
  return stages[currentIndex + 1];
};

export const getPreviousStage = (
  currentStage: StageEnum,
  boardType: BoardType
): StageEnum | null => {
  const stages = boardType === 'LO' ? loStages : aeStages;
  const currentIndex = stages.indexOf(currentStage);
  if (currentIndex <= 0) {
    return null;
  }
  return stages[currentIndex - 1];
};

export const TopSection = ({
  salesPipeline,
  changeStage,
  closeAsLost,
  reactivate,
  updateSalesPipeline,
  onOpenChange,
  boardType,
  users,
}: TopSectionProps) => {
  const [loading, setLoading] = useState(false);
  const { userDetails } = useAuth();
  const [assignCardConfirmDialog, setAssignCardConfirmDialog] =
    useState<AssignCardConfirmDialogProps>({ open: false });

  const handleUpdateStage = useCallback(
    async (type: 'promote' | 'demote' | 'interested_party') => {
      if (!salesPipeline) {
        return;
      }

      let toStage = getNextStage(
        salesPipeline.stage,
        salesPipeline.boardType
      ) as StageEnum;
      if (type === 'interested_party') {
        toStage = 'interested_party';
      }
      if (isPassingToAE(salesPipeline, toStage) || isPassingToLO(toStage)) {
        if (userDetails.cardPassUserId) {
          setLoading(true);
          await changeStage?.(
            salesPipeline.id,
            salesPipeline.stage,
            toStage as StageEnum,
            userDetails.cardPassUserId
          );
          setLoading(false);
        } else {
          setAssignCardConfirmDialog({
            open: true,
            users: users,
            fromStage: salesPipeline.stage,
            toStage,
            salesPipeline: salesPipeline,
            onConfirm: async (passingToUserId: number): Promise<void> => {
              setLoading(true);
              await changeStage?.(
                salesPipeline.id,
                salesPipeline.stage,
                toStage,
                passingToUserId
              );
              setAssignCardConfirmDialog({ open: false });
              setLoading(false);
            },
            onCancel: () => setAssignCardConfirmDialog({ open: false }),
          });
        }

        return;
      }

      if (type === 'demote') {
        toStage = getPreviousStage(
          salesPipeline.stage,
          salesPipeline.boardType
        ) as StageEnum;
      }

      setLoading(true);
      await changeStage?.(
        salesPipeline.id,
        salesPipeline.stage,
        toStage as StageEnum
      );
      setLoading(false);
    },
    [salesPipeline, changeStage, userDetails.cardPassUserId, users]
  );

  const handleCloseAsLost = useCallback(
    async (lostReasonCode: number, lostReasonDescription: string) => {
      if (!salesPipeline) {
        return;
      }

      setLoading(true);
      await closeAsLost?.(
        salesPipeline.id,
        salesPipeline.stage,
        lostReasonCode,
        lostReasonDescription
      );
      setLoading(false);
    },
    [closeAsLost, salesPipeline]
  );

  const handleUpdateReceptiveness = useCallback(
    async (receptiveness: number) => {
      if (!salesPipeline) {
        return;
      }

      setLoading(true);
      await updateSalesPipeline?.(salesPipeline.id, { receptiveness });
      setLoading(false);
    },
    [salesPipeline, updateSalesPipeline]
  );

  const handleReactivate = useCallback(async () => {
    if (!salesPipeline) {
      return;
    }

    setLoading(true);
    await reactivate?.(salesPipeline.id);
    setLoading(false);
  }, [reactivate, salesPipeline]);

  return (
    <div className="flex space-x-8 w-full">
      <div className="w-[350px]">
        <img
          src="/assets/images/property-placeholder.png"
          alt="Property"
          className="w-[320px]"
        />
      </div>
      {salesPipeline && (
        <div className="space-y-12 flex-1">
          <div className="flex justify-between space-x-20">
            <div
              className={`flex items-center space-x-3 ${isCardDisabled(salesPipeline.boardType, salesPipeline.loStage, salesPipeline.aeStage) ? 'pointer-events-none opacity-60' : ''}`}
            >
              {!['lost', 'ae_lost'].includes(salesPipeline.stage) && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button disabled={loading} variant="blue">
                      <span>
                        RECEPTIVENESS
                        {salesPipeline.receptiveness
                          ? `: ${salesPipeline.receptiveness}/5`
                          : ''}
                      </span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
                    <DropdownMenuGroup>
                      {[
                        'Not at all receptive',
                        'Slightly receptive',
                        'Moderately receptive',
                        'Very receptive',
                        'Extremely receptive',
                      ].map((description, index) => (
                        <DropdownMenuCheckboxItem
                          key={index}
                          checked={salesPipeline.receptiveness === index + 1}
                          className="focus:text-blue-tint focus:bg-blue-blue"
                          onClick={() => handleUpdateReceptiveness(index + 1)}
                        >
                          {index + 1} - {description}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}

              {['lost', 'ae_lost'].includes(salesPipeline.stage) && (
                <Button
                  onClick={async () => {
                    await handleReactivate();
                  }}
                  disabled={loading}
                  className="uppercase text-label-md text-green-shade bg-green-tint hover:text-green-tint hover:bg-green-green active:text-green-tint active:bg-green-shade flex items-center space-x-1.5"
                >
                  <span>
                    <TickButtonIcon />
                  </span>
                  <span>REACTIVATE</span>
                </Button>
              )}

              {[
                'assigned_lead',
                'follow_up',
                'front_line_processing',
                'refinancing',
                'potential_lead',
                'first_call',
                'second_call',
                'third_call',
              ].includes(salesPipeline.stage) && (
                <ButtonGroup>
                  <Button
                    onClick={async () => {
                      await handleUpdateStage('demote');
                    }}
                    disabled={
                      loading ||
                      salesPipeline.stage === 'assigned_lead' ||
                      salesPipeline.stage === 'potential_lead'
                    }
                    variant="yellow"
                  >
                    <span>
                      <ArrowLeftIcon />
                    </span>
                    <span>DEMOTE</span>
                  </Button>

                  <Button
                    onClick={async () => {
                      await handleUpdateStage('promote');
                    }}
                    disabled={loading}
                    variant="green"
                  >
                    <span>PROMOTE</span>
                    <span>
                      <ArrowRightIcon />
                    </span>
                  </Button>
                </ButtonGroup>
              )}

              {[
                'assigned_lead',
                'follow_up',
                'front_line_processing',
                'refinancing',
                'potential_lead',
                'first_call',
                'second_call',
                'third_call',
              ].includes(salesPipeline.stage) && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button disabled={loading} variant="red">
                      <span>
                        <TickButtonIcon />
                      </span>
                      <span>CLOSE AS LOST</span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-64 bg-red-tint text-red-shade">
                    <DropdownMenuGroup>
                      {AllLostReasons.map((description, index) => (
                        <DropdownMenuItem
                          key={index}
                          className="focus:text-red-tint focus:bg-red-red"
                          onClick={() =>
                            handleCloseAsLost(index + 1, description)
                          }
                        >
                          {index + 1} - {description}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
            <div>
              <Button onClick={() => onOpenChange?.(false)}>Close</Button>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <StageDetails salesPipeline={salesPipeline} boardType={boardType} />

            {salesPipeline.boardType === 'AE' &&
              salesPipeline?.aeStage !== 'ae_lost' &&
              salesPipeline?.aeStage !== 'interested_party' && (
                <div className="flex justify-between">
                  <div></div>
                  <Button
                    onClick={async () => {
                      await handleUpdateStage('interested_party');
                    }}
                    disabled={loading}
                    variant="green"
                  >
                    <span>Affirmed</span>
                  </Button>
                </div>
              )}
          </div>
        </div>
      )}
      <AssignCardConfirmDialog {...assignCardConfirmDialog} />
      {!salesPipeline && (
        <div className="space-y-12 flex-1">
          <div className="w-full flex justify-between space-x-20">
            <div></div>
            <div>
              <Button onClick={() => onOpenChange?.(false)}>Close</Button>
            </div>
          </div>

          <div>
            <StageDetails salesPipeline={salesPipeline} />
          </div>
        </div>
      )}
    </div>
  );
};
