import { Badge } from '@/components/ui/badge';
import { UserPerformance } from '@/pages/admin/user-performance/types';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { ReactTable } from '@/components/table/react-table';

interface UserPerformanceProps {
  users: UserPerformance[];
}

const userTypeMapping: Record<string, string> = {
  LO: 'Loan Officer',
  AE: 'Account Executive',
};

const mapColumns = [
  { key: 'editNotesCount', title: 'Notes Edited' },
  { key: 'editTaskCount', title: 'Tasks Edited' },
  { key: 'userLoginCount', title: 'Login' },
  { key: 'viewMapCount', title: 'View Map' },
  { key: 'viewMarketCount', title: 'View Market' },
  { key: 'viewPropertyCardCount', title: 'View Property Card' },
  { key: 'viewLeadStagesCount', title: 'View LO CRM' },
  { key: 'viewSalesFunnelCount', title: 'View LO Funnel' },
  { key: 'viewTeamCount', title: 'View LO Team' },
  { key: 'viewAeLeadStagesCount', title: 'View AE CRM' },
  { key: 'viewAeSalesFunnelCount', title: 'View AE Funnel' },
  { key: 'viewAeTeamCount', title: 'View AE Team' },
  { key: 'viewAdminCount', title: 'View Admin' },
];

export const UserPerformanceTable = ({ users }: UserPerformanceProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const columns: ColumnDef<UserPerformance>[] = useMemo(() => {
    return [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        accessorKey: 'fullName',
        meta: {
          title: 'Full Name',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          return <div className="w-40">{row.getValue('fullName')}</div>;
        },

        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'email',
        meta: {
          title: 'Email',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => <div className="w-40">{row.getValue('email')}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'isActive',
        meta: {
          title: 'Status',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          return user.isActive ? (
            <Badge variant="green">Active</Badge>
          ) : (
            <Badge variant="red">Inactive</Badge>
          );
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'isSuperuser',
        meta: {
          title: 'User Type',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          return (
            <span>
              {user.isSuperuser ? 'Admin' : userTypeMapping[user.userType]}
            </span>
          );
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'loAssignedLeadsCap',
        meta: {
          title: 'Assigned Leads Cap',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          if (user.isSuperuser) {
            return <div>No Limit</div>;
          }

          return (
            <div>{user.userType === 'LO' ? user.loAssignedLeadsCap : ''}</div>
          );
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'loAssignedLeads',
        meta: {
          title: 'Leads Currently Assigned',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          if (user.userType === 'AE') {
            return <div>&nbsp;</div>;
          }

          return <div>{user.loAssignedLeads}</div>;
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'aeAssignedLeadsCap',
        meta: {
          title: 'AE Potential Leads Cap',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          if (user.userType === 'LO') {
            return <div>&nbsp;</div>;
          }

          return (
            <div>{user.isSuperuser ? 'No Limit' : user.aeAssignedLeadsCap}</div>
          );
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'aePotentialLeads',
        meta: {
          title: 'AE Potential Leads',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const user = row.original as UserPerformance;
          if (user.userType === 'LO') {
            return <div>&nbsp;</div>;
          }

          return <div>{row.getValue('aePotentialLeads')}</div>;
        },
        enableSorting: true,
        enableHiding: false,
      },
      ...mapColumns.map((mapColumn) => {
        const columnDef: ColumnDef<UserPerformance> = {
          accessorKey: mapColumn.key,
          meta: {
            title: mapColumn.title,
          },
          header: ({ column }) => <DataTableColumnHeader column={column} />,
          cell: ({ row }) => {
            return <div>{row.getValue(mapColumn.key)}</div>;
          },
          enableSorting: true,
          enableHiding: false,
        };

        return columnDef;
      }),
    ];
  }, []);

  const table = useReactTable<UserPerformance>({
    data: users ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="relative w-full overflow-auto">
      <ReactTable table={table} />
    </div>
  );
};
