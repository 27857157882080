import { Skeleton } from '@/components/ui/skeleton';
import { StateStatsData } from '@/pages/market/market/types';
import { formatInteger } from '@/utils/number';
import { Stats } from '@/components';
import { formatShortestMoney } from '@/utils';

interface MarketStatsProps {
  data?: StateStatsData[];
  loading: boolean;
}

export const MarketStats = ({ data, loading }: MarketStatsProps) => {
  const totalOpportunities = data?.reduce(
    (sum, county) =>
      sum +
      county.quartiles.reduce(
        (countySum, quartile) => countySum + quartile.count,
        0
      ),
    0
  );

  const totalMarketValue = data?.reduce(
    (sum, county) =>
      sum +
      county.quartiles.reduce(
        (countySum, quartile) => countySum + quartile.total,
        0
      ),
    0
  );

  return (
    <>
      <Stats
        icon={
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8 0H0.799988V28H23.2V8.4L14.8 0ZM12 11.2C13.54 11.2 14.8 12.46 14.8 14C14.8 15.54 13.54 16.8 12 16.8C10.46 16.8 9.19999 15.54 9.19999 14C9.19999 12.46 10.46 11.2 12 11.2ZM17.6 22.4H6.39999V21.602C6.39999 20.468 7.07199 19.46 8.10799 19.012C9.33558 18.4762 10.6606 18.1997 12 18.1997C13.3394 18.1997 14.6644 18.4762 15.892 19.012C16.3992 19.2288 16.8315 19.5899 17.1352 20.0504C17.4388 20.5108 17.6005 21.0504 17.6 21.602V22.4Z"
              fill="#FFF5E9"
            />
          </svg>
        }
        value={
          loading ? (
            <Skeleton className="h-9 w-[150px]" />
          ) : (
            formatInteger(totalOpportunities)
          )
        }
        label={
          <>
            Platform Leads
            <br />
            available
          </>
        }
      />
      <Stats
        variant="green-light"
        icon={
          <svg
            width="19"
            height="32"
            viewBox="0 0 19 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9388 21.0286C18.9388 25.034 16.4136 27.1673 11.2762 27.5156V32H7.7932V27.4721C2.26395 26.8626 0.0870748 23.7279 0 19.5918H3.22177C3.48299 22.5524 4.96326 24.9034 9.83945 24.9034C14.019 24.9034 15.4993 23.5973 15.4993 21.2027C15.4993 18.9823 14.2803 17.8939 11.5374 17.502L6.48707 16.7619C2.69932 16.2395 0.565986 14.2803 0.565986 10.8844C0.565986 7.31429 3.30884 4.91973 7.7932 4.44082V0H11.2762V4.44082C15.8476 5.0068 18.2857 7.66258 18.5034 11.3197H15.0639C14.8463 9.05578 13.4095 7.00952 9.40408 7.00952C5.7034 7.00952 3.9619 8.4898 3.9619 10.6231C3.9619 12.1469 4.74558 13.4531 7.48843 13.8449L12.4082 14.5415C17.1102 15.2381 18.9388 17.415 18.9388 21.0286Z"
              fill="#056B3A"
            />
          </svg>
        }
        value={
          loading || !totalMarketValue ? (
            <Skeleton className="h-9 w-[150px]" />
          ) : (
            formatShortestMoney(totalMarketValue)
          )
        }
        label={
          <>
            Refinanceable Mortgage
            <br />
            Portfolio Value
          </>
        }
      />
      <Stats
        variant="green"
        icon={
          <svg
            width="19"
            height="32"
            viewBox="0 0 19 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9388 21.0286C18.9388 25.034 16.4136 27.1673 11.2762 27.5156V32H7.7932V27.4721C2.26395 26.8626 0.0870748 23.7279 0 19.5918H3.22177C3.48299 22.5524 4.96326 24.9034 9.83945 24.9034C14.019 24.9034 15.4993 23.5973 15.4993 21.2027C15.4993 18.9823 14.2803 17.8939 11.5374 17.502L6.48707 16.7619C2.69932 16.2395 0.565986 14.2803 0.565986 10.8844C0.565986 7.31429 3.30884 4.91973 7.7932 4.44082V0H11.2762V4.44082C15.8476 5.0068 18.2857 7.66258 18.5034 11.3197H15.0639C14.8463 9.05578 13.4095 7.00952 9.40408 7.00952C5.7034 7.00952 3.9619 8.4898 3.9619 10.6231C3.9619 12.1469 4.74558 13.4531 7.48843 13.8449L12.4082 14.5415C17.1102 15.2381 18.9388 17.415 18.9388 21.0286Z"
              fill="#08A057"
            />
          </svg>
        }
        value={
          loading || !totalMarketValue || !totalOpportunities ? (
            <Skeleton className="h-9 w-[150px]" />
          ) : (
            formatShortestMoney(totalMarketValue / totalOpportunities)
          )
        }
        label={
          <>
            Average Refinanceable
            <br />
            Mortgage Balance
          </>
        }
      />
    </>
  );
};
