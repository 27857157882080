import { Sidebar } from '@/pages/settings/common/sidebar';
import { Input } from '@/components/ui/input';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { EditIcon } from '@/components/icons';
import { useApi } from '@/hooks/use-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { User, UserListResponse } from '@/pages';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Loader } from '@/components';
import { useToast } from '@/components/ui/use-toast';
import { useAuth, UserDetails } from '@/context';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TIMEZONES, TimezoneType } from '@/utils/timezones';
import { Label } from '@/components/ui/label';

export interface ProfileForm extends Partial<UserDetails> {}

export const Profile = () => {
  const [state, setState] = useState<{ editing: boolean }>({ editing: false });
  const { getRequest, patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();
  const { toast } = useToast();
  const { isPending, error, data } = useQuery<User>({
    queryKey: ['me'],
    queryFn: () => getRequest('/api/me'),
  });
  const { data: userData } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
  });

  const [profileForm, setProfileForm] = useState<ProfileForm>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    timezone: 'PT',
  });

  const { isPending: isUpdating, mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
    onSuccess: () => {
      toast({
        variant: 'success',
        description: 'Your profile has been updated successfully.',
        title: 'Profile Updated',
      });
      setState((prevState) => ({ ...prevState, editing: false }));
      if (userDetails) {
        updateUserDetails({
          ...userDetails,
          firstName: profileForm.firstName ?? '',
          lastName: profileForm.lastName ?? '',
          email: profileForm.email ?? '',
          timezone: profileForm.timezone ?? userDetails.timezone,
          cardPassUserId: profileForm.cardPassUserId
            ? profileForm.cardPassUserId
            : null,
        });
      }
    },
    onError: (error) => {
      console.error('Failed to update profile', error);

      const errorMessage =
        (error as Error).message || 'An unknown error occurred';

      toast({
        variant: 'destructive',
        description: errorMessage,
        title: 'Failed to Update Profile',
      });
    },
  });

  const [users, setUsers] = useState<User[]>([]);

  const resetForm = useCallback(() => {
    if (userData && data) {
      setProfileForm((prevState) => ({
        ...prevState,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        timezone: data.timezone,
        cardPassUserId: data.cardPassUserId,
      }));
    }
  }, [data, userData]);

  useEffect(() => {
    if (userData) {
      setUsers(userData.data);
    }

    resetForm();
  }, [userData, data, resetForm]);

  const handleSubmit = useCallback(async () => {
    await updateProfile({ form: profileForm });
  }, [updateProfile, profileForm]);

  const activeLoUsers = users.filter(
    (u) => u.isActive && u.userType === 'LO' && !u.isSuperuser
  );
  const activeAeUsers = users.filter(
    (u) => u.isActive && u.userType === 'AE' && !u.isSuperuser
  );

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Sidebar currentPage="profile">
      <div>
        <div className="text-heading-01">Profile</div>
      </div>
      {isPending ? (
        <Loader />
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmit();
          }}
        >
          <div className="my-12">
            <div className="flex space-x-6">
              <div>
                <img
                  src="/assets/images/avatar.png"
                  alt="Avatar"
                  className="h-32"
                />
              </div>
              <div className="space-y-6">
                <div className="space-x-6 flex">
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="firstName">First name</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      disabled={!state.editing}
                      required
                      className="w-[376px]"
                      value={profileForm.firstName ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="lastName">Last name</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      disabled={!state.editing}
                      required
                      className="w-[376px]"
                      value={profileForm.lastName ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="space-x-6 flex">
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="email">Last name</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      disabled={!state.editing}
                      required
                      className="w-[376px]"
                      value={profileForm.email ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="phone">Phone number</Label>
                    <Input
                      id="phone"
                      name="phone"
                      disabled={!state.editing}
                      className="w-[376px]"
                      value={profileForm.phone ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          phone: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="space-x-6 flex">
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="timezone">Time zone</Label>
                    <Select
                      name="timezone"
                      value={profileForm.timezone ?? 'PT'}
                      disabled={!state.editing}
                      onValueChange={(value) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          timezone: value as TimezoneType,
                        }));
                      }}
                    >
                      <SelectTrigger className="w-[376px]">
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {Object.entries(TIMEZONES)
                            .sort()
                            .map(([key, value]) => (
                              <SelectItem key={key} value={key}>
                                {value} ({key})
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label htmlFor="cardPassUserId">Card Pass Selection</Label>
                    <Select
                      name="cardPassUserId"
                      value={profileForm.cardPassUserId?.toString() ?? 'null'}
                      disabled={!state.editing}
                      onValueChange={(value) => {
                        if (value) {
                          setProfileForm((prevState) => ({
                            ...prevState,
                            cardPassUserId:
                              value === 'null' ? null : parseInt(value, 10),
                          }));
                        }
                      }}
                    >
                      <SelectTrigger className="w-[376px]">
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent id="cardPassUserId">
                        <SelectGroup>
                          <SelectItem value="-1">
                            {data.userType === 'LO'
                              ? 'AE Bypass'
                              : 'Original Owner'}
                          </SelectItem>
                          {(userDetails.userType === 'LO'
                            ? activeAeUsers
                            : activeLoUsers
                          ).map((user) => (
                            <SelectItem
                              key={user.id}
                              value={user.id.toString()}
                            >
                              {user.firstName} {user.lastName}
                              {' - '}
                              {user.isSuperuser ? 'Admin' : user.userType}
                            </SelectItem>
                          ))}
                          <SelectItem value="null">Prompt User</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex justify-end space-x-6 items-center">
                  {!state.editing && (
                    <button
                      className="w-6 h-6 disabled:cursor-not-allowed disabled:text-orange-shade"
                      disabled={state.editing}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          editing: true,
                        }))
                      }
                    >
                      <EditIcon />
                    </button>
                  )}
                  {state.editing && (
                    <Button
                      variant="outline"
                      type="button"
                      disabled={isUpdating}
                      onClick={() => {
                        resetForm();
                        setState((prevState) => ({
                          ...prevState,
                          editing: false,
                        }));
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    disabled={!state.editing || isUpdating}
                    className="disabled:cursor-not-allowed"
                    type="submit"
                  >
                    {isUpdating ? 'SAVING CHANGES' : 'SAVE CHANGES'}
                  </Button>
                </div>
              </div>
            </div>
            <hr className="border-navy-navy my-4" />
          </div>
        </form>
      )}
    </Sidebar>
  );
};
