import { useCallback, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { CalendarIcon, EditIcon } from '@/components/icons';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { TickButtonIcon } from '@/components/map/property-details/icons/tick-button-icon';
import { useApi } from '@/hooks/use-api';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { DueDateSetting } from '@/components/map/property-details/due-date-setting';
import { DueDatetimeLabel } from '@/components/map/property-details/due-datetime-label';
import { areDatetimeEqual } from '@/utils/date';
import { useAuth, useNotificationContext } from '@/context';
import {
  Table,
  TableHeader,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@/components/ui/table';
import { OutboundCall } from '@/components/map/property-details/types';
import {
  getDatetimeFormattedString,
  getTimezoneString,
  TimezoneType,
} from '@/utils/timezones';
import { addDays } from 'date-fns';

interface NotesDetailsProps {
  salesPipeline: SalesPipeline;
  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
  outboundCalls?: OutboundCall[];
}

interface State {
  editingNotes: boolean;
  editingTask: boolean;
  updating: boolean;
  notes: string;
  notesUpdatedAt?: string;
  task: string;
  taskCompleted: boolean;
  taskDueDatetime?: string;
  taskTimezone: TimezoneType;
}

const CHARACTER_LIMIT = 1500;

const defaultTaskDueDatetime = (timezone: TimezoneType) => {
  const now = new Date();
  const tomorrow = addDays(now, 1);
  const date = tomorrow.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
  const time = '19:00:00'; // Set time to 7:00 PM

  return getTimezoneString({ date, time, timezone });
};

export const NotesTaskDetails = ({
  salesPipeline,
  updateSalesPipeline,
  outboundCalls,
}: NotesDetailsProps) => {
  const { userDetails } = useAuth();

  const [state, setState] = useState<State>({
    editingNotes: false,
    editingTask: false,
    updating: false,
    notes: '',
    task: '',
    taskCompleted: false,
    taskTimezone:
      salesPipeline?.taskTimezone ??
      salesPipeline?.property?.timezone ??
      salesPipeline?.manualProperty?.timezone ??
      'PT',
  });
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { sendEvent } = useApi();
  const { refetchNotifications } = useNotificationContext();

  const handleUpdate = useCallback(
    async (data: UpdateSalesPipeline) => {
      setState((prevState) => ({ ...prevState, updating: true }));

      try {
        await updateSalesPipeline?.(salesPipeline.id, data);
        if (data.notes !== undefined) {
          setState((prevState) => ({ ...prevState, editingNotes: false }));
          await sendEvent('edit_notes', { salesPipelineId: salesPipeline.id });
        }

        if (data.task !== undefined) {
          setState((prevState) => ({ ...prevState, editingTask: false }));
          await sendEvent('edit_task', { salesPipelineId: salesPipeline.id });
        }
      } finally {
        setState((prevState) => ({ ...prevState, updating: false }));
      }

      refetchNotifications();
    },
    [refetchNotifications, salesPipeline.id, sendEvent, updateSalesPipeline]
  );

  const setDatetime = (datetime: string, timezone: TimezoneType) => {
    setState((prevState) => ({
      ...prevState,
      taskDueDatetime: datetime,
      taskTimezone: timezone,
    }));
  };

  return (
    <Tabs defaultValue="notes" className="w-full">
      <TabsList>
        <TabsTrigger value="notes">Notes</TabsTrigger>
        <TabsTrigger value="task">Task</TabsTrigger>
        <TabsTrigger value="calls">Outbound Calls</TabsTrigger>
      </TabsList>
      <TabsContent value="notes">
        {!state.editingNotes && (
          <div className="bg-light-light p-6 rounded space-y-2 text-paragraph-md">
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    editingNotes: true,
                    notes: salesPipeline.notes ?? '',
                  }))
                }
              >
                <EditIcon />
              </button>
            </div>

            {!salesPipeline.notes && (
              <div className="min-h-[180px] text-navy-tint">
                Add Your First Note
              </div>
            )}

            {salesPipeline.notes && (
              <>
                <div className="h-[180px] text-navy-navy whitespace-pre-wrap overflow-y-auto">
                  {salesPipeline.notes}
                </div>
                {salesPipeline.notesUpdatedAt && (
                  <div className="text-paragraph-md text-navy-navy">
                    Last Updated:{' '}
                    {getDatetimeFormattedString(
                      salesPipeline.notesUpdatedAt,
                      userDetails.timezone
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {state.editingNotes && (
          <div className="space-y-3">
            <Textarea
              value={state.notes}
              onChange={(e) => {
                const notes = e.currentTarget.value;
                if (notes.length <= CHARACTER_LIMIT) {
                  setState((prevState) => ({
                    ...prevState,
                    notes,
                  }));
                }
              }}
              className="bg-light-light p-6 rounded space-y-9 text-paragraph-md min-h-[230px]"
            />
            <div className="text-right text-paragraph-sm text-gray-500">
              {state.notes.length}/{CHARACTER_LIMIT} characters
            </div>
            <div className="flex justify-end space-x-3">
              <Button
                variant="ghost"
                disabled={state.updating}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    editingNotes: false,
                  }))
                }
              >
                Cancel
              </Button>
              <Button
                disabled={state.updating}
                onClick={() => handleUpdate({ notes: state.notes })}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </TabsContent>
      <TabsContent value="task">
        {!state.editingTask && (
          <div className="space-y-3">
            {salesPipeline.taskCompleted && (
              <div className="uppercase text-label-md text-green-shade bg-green-tint flex items-center space-x-1.5 p-2 rounded">
                <span>
                  <TickButtonIcon />
                </span>
                <span>Completed</span>
              </div>
            )}

            <div className="bg-light-light p-6 rounded space-y-2 text-paragraph-md">
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      editingTask: true,
                      task: salesPipeline.task ?? '',
                      taskCompleted: salesPipeline.taskCompleted,
                      taskDueDatetime: salesPipeline.taskDueDatetime,
                    }))
                  }
                >
                  <EditIcon />
                </button>
              </div>

              {!salesPipeline.task && (
                <div className="min-h-[180px] text-navy-tint">
                  Add Your First Task
                </div>
              )}

              {salesPipeline.task && (
                <div className="h-[180px] text-navy-navy whitespace-pre-wrap overflow-y-auto">
                  {salesPipeline.task}
                </div>
              )}
              {!salesPipeline.taskCompleted && (
                <DueDatetimeLabel
                  taskDueDatetime={salesPipeline.taskDueDatetime}
                  timezone={userDetails.timezone ?? 'PT'}
                />
              )}
            </div>
            {!salesPipeline.taskCompleted && (
              <div className="flex justify-end">
                <Button
                  onClick={async () =>
                    handleUpdate({
                      taskCompleted: true,
                    })
                  }
                  variant="green"
                >
                  <span>
                    <TickButtonIcon />
                  </span>
                  <span>Complete</span>
                </Button>
              </div>
            )}
          </div>
        )}

        {state.editingTask && (
          <div className="space-y-3">
            <Textarea
              value={state.task}
              onChange={(e) => {
                const task = e.currentTarget.value;
                if (task.length <= CHARACTER_LIMIT) {
                  setState((prevState) => ({
                    ...prevState,
                    task,
                  }));
                }
              }}
              className="bg-light-light p-6 rounded space-y-9 text-paragraph-md min-h-[230px]"
            />
            <div className="text-right text-sm text-gray-500">
              {state.task.length}/{CHARACTER_LIMIT} characters
            </div>
            <DueDatetimeLabel
              taskDueDatetime={state.taskDueDatetime}
              timezone={state.taskTimezone}
            />
            <div className="flex justify-between items-center">
              <div>
                <Popover
                  modal
                  open={isPopoverOpen}
                  onOpenChange={setPopoverOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="yellow"
                      disabled={state.updating}
                      onClick={() => setPopoverOpen(true)}
                    >
                      <span>
                        <CalendarIcon />
                      </span>
                      <span>Due Date</span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <DueDateSetting
                      dueDatetime={
                        state.taskDueDatetime ??
                        defaultTaskDueDatetime(state.taskTimezone)
                      }
                      setDueDatetime={(datetime, timezone) => {
                        setPopoverOpen(false);
                        setDatetime(datetime, timezone);
                      }}
                      onCancel={() => setPopoverOpen(false)}
                      timezone={state.taskTimezone}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex justify-end items-center space-x-3">
                <Button
                  variant="ghost"
                  disabled={state.updating}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      editingTask: false,
                    }))
                  }
                >
                  Cancel
                </Button>
                <Button
                  disabled={state.updating}
                  onClick={() =>
                    handleUpdate({
                      task: state.task,
                      taskDueDatetime: state.taskDueDatetime,
                      taskTimezone: state.taskTimezone,
                      taskCompleted:
                        state.task !== salesPipeline.task ||
                        !areDatetimeEqual(
                          state.taskDueDatetime,
                          salesPipeline.taskDueDatetime
                        )
                          ? false
                          : salesPipeline.taskCompleted,
                    })
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </TabsContent>
      <TabsContent value="calls">
        <div className="h-[310px] overflow-y-auto border">
          <Table className="table">
            <TableHeader>
              <TableRow>
                <TableHead className="text-paragraph-sm p-1">
                  Timestamp
                </TableHead>
                <TableHead className="text-paragraph-sm p-1">Owner</TableHead>
                <TableHead className="text-paragraph-sm p-1">Phone</TableHead>
                <TableHead className="text-paragraph-sm p-1">User</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {outboundCalls?.map((call) => (
                <TableRow>
                  <TableCell className="text-paragraph-sm text-[12px] px-1 py-2">
                    {getDatetimeFormattedString(
                      call.callTimestamp,
                      userDetails.timezone
                    )}
                  </TableCell>
                  <TableCell className="text-paragraph-sm text-[12px] px-1 py-2">
                    {call.ownerName}
                  </TableCell>
                  <TableCell className="text-paragraph-sm text-[12px] px-1 py-2">
                    {call.phoneNumber}
                  </TableCell>
                  <TableCell className="text-paragraph-sm text-[12px] px-1 py-2">
                    {call.userName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TabsContent>
    </Tabs>
  );
};
