import { SalesFunnelStatsSection } from '@/pages/crm/sales-funnel/sales-funnel-stats-section';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { AggregateSumChart } from '@/pages/crm/sales-funnel/aggregate-sum-chart';
import { ConversionRateChart } from '@/components/charts/conversion-rate-chart/conversion-rate-chart';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { SalesFunnelStatsResponse } from '@/pages/crm/sales-funnel/types';
import { useAuth } from '@/context';
import { User, UserListResponse } from '@/pages';
import { useEffect, useState } from 'react';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { SalesFunnelChart } from '@/components';
import { BoardType } from '@/pages/crm/lead-stages/types';
import { UserDropdown } from '@/components/filters/common/user-dropdown';

interface SalesFunnelProps {
  boardType: BoardType;
}

export const SalesFunnelContainer = ({ boardType }: SalesFunnelProps) => {
  const { getRequest } = useApi();
  const { userDetails } = useAuth();

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const [includeCurrentMonth, setIncludeCurrentMonth] = useState(
    userDetails.isSuperuser
  );

  const { data: userList } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
    enabled: userDetails.isSuperuser,
  });

  const { data } = useQuery<SalesFunnelStatsResponse>({
    queryKey: [
      'sales-funnel-stats',
      boardType,
      selectedUser?.id,
      includeCurrentMonth,
    ],
    queryFn: () =>
      getRequest(
        `/api/stats/sales_funnel_stats?board_type=${boardType.toLowerCase()}${userDetails.isSuperuser && selectedUser ? `&user_id=${selectedUser.id}` : ''}${includeCurrentMonth ? '&include_current_month=true' : ''}`
      ),
  });

  useEffect(() => {
    if (userList?.data) {
      const user = userList?.data.find(
        (user) => user.email === userDetails.email
      );

      setSelectedUser(user);
    }
  }, [userDetails.email, userList?.data, boardType]);

  const fifthStageLabel =
    boardType === 'LO' ? 'Refinanced Leads' : 'Interested Parties';

  return (
    <div
      className="flex w-full space-x-5"
      style={{ height: 'calc(100vh - 108px)' }}
    >
      <div className="w-full space-y-6 h-full overflow-y-auto bg-gradient-to-b from-[#e5e7ee] to-[#e5e7ee] rounded-lg p-3">
        <div className="grid gap-4 grid-cols-1 lg:gap-8 xl:grid-cols-4">
          <SalesFunnelStatsSection data={data?.chartData} type={boardType} />
          <div className="flex flex-col xl:justify-end items-end space-y-4">
            <CheckboxWithLabel
              label="Include current month"
              checked={includeCurrentMonth}
              onClick={() => setIncludeCurrentMonth(!includeCurrentMonth)}
            />
            {userList && (
              <UserDropdown
                users={userList.data ?? []}
                selectedUserId={selectedUser?.id}
                onUserChange={(userId) =>
                  setSelectedUser(userList.data.find((u) => u.id === userId))
                }
                borderType={boardType}
                alwaysIncludeCurrentUser
              />
            )}
          </div>
        </div>

        <div className="grid gap-4 grid-cols-1 lg:gap-8 xl:grid-cols-2">
          <div>
            <FilterContainer
              value="sales-funnel"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  {boardType} Funnel
                </div>
              }
            >
              <div className="mt-6">
                <div className="w-full flex items-center justify-center h-[430px]">
                  <SalesFunnelChart
                    data={data?.salesFunnel}
                    boardType={boardType}
                  />
                </div>
              </div>
            </FilterContainer>
          </div>
          <div>
            <FilterContainer
              value="conversion-rate"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  Conversion Rate
                </div>
              }
            >
              <ConversionRateChart
                data={data?.chartData ?? []}
                boardType={boardType}
              />
            </FilterContainer>
          </div>
        </div>

        <div className="grid gap-4 grid-cols-1">
          <div>
            <FilterContainer
              value="aggregate-sum-of-refinanced-mortgage-balance"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  Aggregate Sum of {fifthStageLabel} Mortgage Balances
                </div>
              }
            >
              <AggregateSumChart
                data={data?.chartData ?? []}
                boardType={boardType}
              />
            </FilterContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
