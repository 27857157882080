import { format as formatTz, toZonedTime } from 'date-fns-tz';

export type TimezoneType = 'PT' | 'MT' | 'CT' | 'ET' | 'AKT' | 'HAT';

export const TIMEZONES: Record<TimezoneType, string> = {
  AKT: 'The Alaska Time Zone',
  CT: 'The Central Time Zone',
  ET: 'The Eastern Time Zone',
  HAT: 'The Hawaii-Aleutian Time Zone',
  MT: 'The Mountain Time Zone',
  PT: 'The Pacific Time Zone',
};

export const TIMEZONE_MAPPING: Record<TimezoneType, string> = {
  AKT: 'America/Anchorage',
  CT: 'America/Chicago',
  ET: 'America/New_York',
  HAT: 'Pacific/Honolulu',
  MT: 'America/Denver',
  PT: 'America/Los_Angeles',
};

export const getTimezoneString = ({
  date,
  time,
  timezone,
}: {
  date: string;
  time: string;
  timezone?: TimezoneType;
}): string => {
  const dateTimeString = `${date}T${time}`;
  const dateTime = new Date(dateTimeString);

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: TIMEZONE_MAPPING[timezone ?? 'PT'],
    timeZoneName: 'shortOffset',
  });
  const parts = formatter.formatToParts(dateTime);
  let timeZoneOffset = parts
    .find((part) => part.type === 'timeZoneName')
    ?.value.replace('GMT-', '');

  if (timeZoneOffset && !timeZoneOffset.includes(':')) {
    timeZoneOffset = timeZoneOffset.padStart(2, '0') + ':00';
  }

  return `${date}T${time}-${timeZoneOffset}`;
};

export const getLocalDatetime = (
  isoDateTimeString: string,
  timezone: TimezoneType
) => {
  const utcDate = new Date(isoDateTimeString);
  const timeZone = TIMEZONE_MAPPING[timezone];
  const zonedDate = toZonedTime(utcDate, timeZone);

  const formattedDate = formatTz(zonedDate, 'yyyy-MM-dd', { timeZone });
  const formattedTime = formatTz(zonedDate, 'HH:mm:ss', { timeZone });

  return { date: formattedDate, time: formattedTime, timezone };
};

export const getDatetimeFormattedString = (
  isoDateTimeString: string,
  timezone: TimezoneType
): string => {
  const utcDate = new Date(isoDateTimeString);
  const timeZone = TIMEZONE_MAPPING[timezone];
  const zonedDate = toZonedTime(utcDate, timeZone);

  const formattedDate = formatTz(zonedDate, 'dd MMM yyyy', { timeZone });
  const formattedTime = formatTz(zonedDate, 'hh:mm a', { timeZone });

  return `${formattedDate} at ${formattedTime} ${timezone}`;
};
