import { IconProps } from '@/components/ui/icons/types';

export const ShakeHandIcon = ({ className }: IconProps) => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect y="0.710449" width="36" height="36" rx="18" fill="#000723" />
    <path
      d="M14.9749 8.21045L17.9997 11.2352L21.0245 8.21045L30.0121 17.1981L17.9997 29.2104L5.9873 17.1981L14.9749 8.21045ZM16.574 12.6609L14.9749 11.0618L8.83866 17.1981L17.9997 26.3591L18.9616 25.3972L16.574 23.0087L17.9997 21.584L20.3872 23.9725L21.3501 23.0097L18.9616 20.6221L20.3872 19.1964L22.7758 21.584L24.136 20.2238L21.0245 17.1114L17.9997 20.1361L13.5492 15.6857L16.574 12.6609ZM25.5616 18.7972L27.1607 17.1981L21.0245 11.0618L16.4006 15.6857L17.9997 17.2848L21.0245 14.26L25.5616 18.7972Z"
      fill="#FEFEFF"
    />
  </svg>
);
