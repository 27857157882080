import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { MarketFilter } from '@/components/market/assign-leads-dialog/type';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { formatShortestMoney, stateMap } from '@/utils';
import { useEffect, useState } from 'react';
import { User } from '@/pages';
import { useAuth } from '@/context';
import { useToast } from '@/components/ui/use-toast';

interface AssignLeadsDialogProps {
  filter: MarketFilter;
  open: boolean;
  onClose?(): void;
  numberOfAllowedAssignedLeads?: number;
  assignLeads?(numberOfLeads: number, userId?: number): void;
  assigning?: boolean;
  users?: User[];
  isAdmin?: boolean;
}

interface FormState {
  count: number;
  userId?: number;
}

export const AssignLeadsDialog = ({
  filter,
  open,
  onClose,
  numberOfAllowedAssignedLeads,
  assignLeads,
  assigning,
  users,
  isAdmin,
}: AssignLeadsDialogProps) => {
  const { toast } = useToast();
  const { userDetails, updateProfile } = useAuth();
  const [state, setState] = useState<FormState>({
    count: 100,
  });

  useEffect(() => {
    if (open && userDetails.lastAssignedLeadUserId) {
      setState((prevState) => ({
        ...prevState,
        userId: userDetails.lastAssignedLeadUserId ?? undefined,
      }));
    }
  }, [open, userDetails.lastAssignedLeadUserId]);

  useEffect(() => {
    if (isAdmin) {
      setState((prevState) => ({
        ...prevState,
        count: 100,
      }));
    } else if (numberOfAllowedAssignedLeads) {
      setState((prevState) => ({
        ...prevState,
        count:
          numberOfAllowedAssignedLeads > 100
            ? 100
            : numberOfAllowedAssignedLeads,
      }));
    }
  }, [isAdmin, numberOfAllowedAssignedLeads]);

  return (
    <Dialog open={open} onOpenChange={() => onClose?.()}>
      <DialogContent className="sm:max-w-[900px]">
        <DialogHeader>
          <DialogTitle>Assign Leads</DialogTitle>
        </DialogHeader>
        <div className="flex items-start justify-between 2 gap-4 py-4">
          <div className="space-y-3">
            <div className="text-label-lg">Market Filters Applied</div>
            <div className="flex flex-col space-y-3 max-w-[600px]">
              <div className="space-x-3">
                <Badge variant="blue">
                  Est. Mortgage Balance: $
                  {formatShortestMoney(
                    filter.selectedMortgageBalanceRange.from
                  )}{' '}
                  - $
                  {formatShortestMoney(filter.selectedMortgageBalanceRange.to)}
                </Badge>
                <Badge variant="blue">
                  IR: {filter.selectedAprRange.from}% -{' '}
                  {filter.selectedAprRange.to}%
                </Badge>
              </div>
              <div className="space-x-3">
                <Badge variant="blue">
                  Build Yr: {filter.selectedBuildYearRange.from} -{' '}
                  {filter.selectedBuildYearRange.to}
                </Badge>
                <Badge variant="blue">
                  Mortgage Type:{' '}
                  {filter.selectedMortgageTypes
                    .map((type) => MORTGAGE_TYPE_MAP[type])
                    .join(', ')}
                </Badge>
              </div>
              <div>
                <Badge variant="blue">
                  {filter.selectedStates
                    .map((state) => stateMap[state] ?? state)
                    .join(', ')}
                </Badge>
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div className="flex flex-col space-y-3 items-end">
              <div className="text-label-md">Adjust Lead Volume</div>
              <div>
                <Input
                  type="number"
                  className="w-24"
                  value={state.count}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      count: parseInt(e.target.value),
                    }))
                  }
                />{' '}
              </div>
              {numberOfAllowedAssignedLeads !== undefined && !isAdmin && (
                <div className="text-paragraph-md text-red-red">
                  Maximum: {numberOfAllowedAssignedLeads}
                </div>
              )}
            </div>

            <div className="flex flex-col space-y-3 items-end">
              <div className="text-label-md">Assign to</div>
              <div>
                <Select
                  onValueChange={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      userId: parseInt(value),
                    }))
                  }
                  value={state.userId?.toString()}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select a user" />
                  </SelectTrigger>
                  <SelectContent className="max-h-80 overflow-y-auto">
                    <SelectGroup>
                      {users
                        ?.filter((user) => user.isActive)
                        .sort((a, b) => a.lastName.localeCompare(b.lastName))
                        .map((user) => (
                          <SelectItem
                            value={user.id.toString()}
                            key={user.id}
                            className="hover:text-accent-foreground hover:bg-accent"
                          >
                            {user.firstName} {user.lastName}
                            {` - ${user.isSuperuser ? 'Admin' : user.userType}`}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            disabled={
              numberOfAllowedAssignedLeads === undefined ||
              numberOfAllowedAssignedLeads === 0 ||
              state.count > numberOfAllowedAssignedLeads ||
              assigning ||
              (isAdmin && users === undefined) ||
              (isAdmin && state.userId === undefined)
            }
            onClick={async () => {
              assignLeads?.(state.count, state.userId);
              const userType =
                users?.find((user) => user.id === state.userId)?.userType ??
                'LO';

              toast({
                variant: 'success',
                description: isAdmin
                  ? `The leads have been assigned to the user. They will be able to view the leads in their Map tab or ${userType} CRM tab.`
                  : 'Please return to your Map tab or LO CRM tab to view leads.',
                title: 'Assignment Complete',
              });
              await updateProfile({
                lastAssignedLeadUserId: state.userId,
              });
            }}
          >
            {assigning ? 'Assigning' : 'Assign Now'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
