import { MiniStats } from '@/components';
import { ContactIcon, ShakeHandIcon, CupIcon } from '@/components/icons';
import { DollarIcon } from './dollar-icon';
import { ChartRow } from '@/pages/crm/team/team/types';
import { formatShortestMoney } from '@/utils';
import { SalesFunnel } from '@/pages/crm/sales-funnel/types';
import { calculateConversionRate } from '@/pages/crm/team/team/utils';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface TeamStatsProps {
  data: ChartRow[];
  salesFunnel: SalesFunnel;
  quarterSalesFunnel: SalesFunnel;
  boardType: BoardType;
}

export const TeamStats = ({
  data,
  salesFunnel,
  quarterSalesFunnel,
  boardType,
}: TeamStatsProps) => {
  const totalLeadsFollowUp = (data: ChartRow[]) =>
    data.reduce((acc, row) => acc + row.leadFollowUp, 0);

  const totalLeadsRefinanced = (data: ChartRow[]) =>
    data.reduce((acc, row) => acc + row.leadRefinanced, 0);

  const totalRefinancedAmount = (data: ChartRow[]) =>
    data.reduce((acc, row) => acc + row.leadRefinancedAmount, 0);

  const monthlyLeadsFollowUp = totalLeadsFollowUp(data.slice(-1));
  const quarterlyLeadsFollowUp = quarterSalesFunnel.followUp;
  const yearlyLeadsFollowUp = salesFunnel.followUp;

  const monthlyLeadsRefinanced = totalLeadsRefinanced(data.slice(-1));
  const quarterlyLeadsRefinanced = quarterSalesFunnel.refinanced;
  const yearlyLeadsRefinanced = salesFunnel.refinanced;

  const monthlyRefinancedAmount = totalRefinancedAmount(data.slice(-1));
  const quarterlyRefinancedAmount = totalRefinancedAmount(data.slice(-3));
  const yearlyRefinancedAmount = totalRefinancedAmount(data);

  const monthlyConversionRate = calculateConversionRate(
    monthlyLeadsRefinanced,
    monthlyLeadsFollowUp
  );
  const quarterlyConversionRate = calculateConversionRate(
    quarterlyLeadsRefinanced,
    quarterlyLeadsFollowUp
  );
  const yearlyConversionRate = calculateConversionRate(
    yearlyLeadsRefinanced,
    yearlyLeadsFollowUp
  );

  const secondStageName =
    boardType === 'LO' ? 'Follow-up' : 'First Calls Make to Leads';
  const fifthStageName =
    boardType === 'LO' ? 'Refinanced Leads' : 'Interested Parties';
  const lastStageName =
    boardType === 'LO' ? 'Refinanced' : 'Interested Parties';

  return (
    <div className="flex flex-col m-6 space-y-6">
      <div className="p-3 grid grid-cols-1 lg:grid-cols-2 gap-6 mx-5">
        <div className="bg-light-tint rounded-lg p-6 flex flex-col space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-heading-04 text-navy-navy">
              {secondStageName}
            </div>
            <div>
              <ContactIcon className="h-9 w-9 text-navy-navy" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="orange-tint"
              value={monthlyLeadsFollowUp?.toLocaleString()}
              label="Monthly"
            />
            <MiniStats
              variant="orange"
              value={quarterlyLeadsFollowUp?.toLocaleString()}
              label="Quarterly"
            />
            <MiniStats
              variant="orange-shade"
              value={yearlyLeadsFollowUp?.toLocaleString()}
              label="Yearly"
            />
          </div>
        </div>

        <div className="bg-light-tint rounded-lg p-6 flex flex-col space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-heading-04 text-navy-navy">
              {fifthStageName}
            </div>
            <div>
              <CupIcon className="h-9 w-9 text-blue-tint fill-blue-shade" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="blue-tint"
              value={monthlyLeadsRefinanced.toLocaleString()}
              label="Monthly"
            />
            <MiniStats
              variant="blue"
              value={quarterlyLeadsRefinanced.toLocaleString()}
              label="Quarterly"
            />
            <MiniStats
              variant="blue-shade"
              value={yearlyLeadsRefinanced.toLocaleString()}
              label="Yearly"
            />
          </div>
        </div>

        <div className="bg-light-tint rounded-lg p-6 flex flex-col space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-heading-04 text-navy-navy">
              Conversion rate
            </div>
            <div>
              <ShakeHandIcon className="h-9 w-9 text-navy-tint" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="navy-tint"
              value={monthlyConversionRate}
              label="Monthly"
            />
            <MiniStats
              variant="navy"
              value={quarterlyConversionRate}
              label="Quarterly"
            />
            <MiniStats
              variant="navy-shade"
              value={yearlyConversionRate}
              label="Yearly"
            />
          </div>
        </div>

        <div className="bg-light-tint rounded-lg p-6 flex flex-col space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-heading-04 text-navy-navy">
              {lastStageName} Mtg. Bals. Sum
            </div>
            <div>
              <DollarIcon className="h-9 w-9 text-green-tint" />
            </div>
          </div>
          <div className="flex space-x-10">
            <MiniStats
              variant="green-tint"
              value={formatShortestMoney(monthlyRefinancedAmount)}
              label="Monthly"
            />
            <MiniStats
              variant="green"
              value={formatShortestMoney(quarterlyRefinancedAmount)}
              label="Quarterly"
            />
            <MiniStats
              variant="green-shade"
              value={formatShortestMoney(yearlyRefinancedAmount)}
              label="Yearly"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
